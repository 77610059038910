<form novalidate="" [formGroup]="form" (ngSubmit)="done()" class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">
    {{
      deletedCompanyIds.length
        | i18nPlural: { '=1': 'settings.companies.deleteCompany', other: 'settings.companies.deleteCompanies' }
        | craftTerm
    }}
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <craft-delete-companies-option-select
      [form]="form"
      [deletedCompanyIds]="deletedCompanyIds"
    ></craft-delete-companies-option-select>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button
        class="btn btn-cancel dialog-btn"
        type="button"
        (click)="close()"
        [disabled]="isRequestSending"
        mat-button=""
        craftDisableFocus
      >
        Cancel
      </button>
      <div
        matTooltip="Choose one of the two options above"
        [matTooltipDisabled]="form.invalid"
        matTooltipPosition="above"
      >
        <button
          class="btn btn-blue dialog-btn"
          type="submit"
          [disabled]="form.invalid || isRequestSending"
          mat-button=""
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</form>
