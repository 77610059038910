export function getFullName(user: any): string {
  if (!user) return '';
  if (typeof user.fullName === 'string') {
    return user.fullName;
  }

  let { firstName, lastName } = user;
  firstName = typeof firstName === 'string' ? firstName : '';
  lastName = typeof lastName === 'string' ? lastName : '';
  return `${firstName} ${lastName}`.trim();
}

export function getMediumName(user: any): string {
  if (!user) return '';
  let { firstName, lastName } = user;
  firstName = typeof firstName === 'string' ? firstName : '';
  lastName = typeof lastName === 'string' && lastName.length > 0 ? lastName[0].toUpperCase() + '.' : '';
  return `${firstName} ${lastName}`.trim();
}

export function getShortName(user: any): string {
  if (!user) return '';
  let { firstName, lastName } = user;
  firstName = typeof firstName === 'string' && firstName.length > 0 ? firstName[0].toUpperCase() : '';
  lastName = typeof lastName === 'string' && lastName.length > 0 ? lastName[0].toUpperCase() : '';
  return `${firstName}${lastName}`;
}

export function compareStrings(a?: string, b?: string, options?: Intl.CollatorOptions) {
  if (!a) return -1;
  if (!b) return 1;
  return a.localeCompare(b, void 0, options);
}

export function safeJSONParse(data: any): any {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}

export function formatNum(num: number | string, format?: string) {
  return format ? format.replace('#', String(num)) : String(num);
}

export function stripTags(inputText?: string) {
  if (!inputText) return '';

  const div = document.createElement('div');
  div.innerHTML = inputText;
  return div.textContent || div.innerText || '';
}
