import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PickRequired, StringTMap } from 'src/app/core/models/common.models';
import {
  DBIdea,
  Idea,
  IdeaChangeTagRequest,
  IdeaChangeWorkflowStatusRequest,
  IdeadAddAttachmentRequest,
  IdeadChangeInnerStatusRequest,
  IdeadDelAttachmentRequest,
  IdeadVotesResponse,
  IdeasBulkUpdateRequest,
  IdeasListRequest,
  IdeasListResponse,
} from 'src/app/core/models/idea.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { UsersBackendService } from 'src/app/core/services/backend/users-backend.service';

@Injectable({
  providedIn: 'root',
})
export class IdeasBackendService {
  constructor(
    private bs: BackendService,
    private ubs: UsersBackendService,
  ) {}

  public getList(req: IdeasListRequest): Observable<IdeasListResponse> {
    return this.bs.post('iportal/all_ideas', req).pipe(
      map((result) => {
        const ideas: StringTMap<DBIdea> = {};
        const ids = result.list.reduce((res: string[], idea: DBIdea) => {
          ideas[idea.id] = this.mapIdea(idea);
          res.push(idea.id);
          return res;
        }, []);

        const response: IdeasListResponse = {
          ids,
          ideas,
          total: Number.parseInt(result.total, 10) || 0,
          totals: Object.keys(result.totals || {}).reduce((acc, key) => {
            acc[key] = Number.parseInt(acc[key], 10) || 0;
            return acc;
          }, result.totals || {}),
        };

        return response;
      }),
    );
  }

  public update(idea: DBIdea): Observable<DBIdea> {
    return this.bs.put(`iportal/idea/${idea.id}`, idea).pipe(
      map((serverIdea) => ({
        ...idea,
        companyId: serverIdea.companyId || idea.companyId,
        creator: {
          ...idea.creator,
          ...serverIdea.creator,
        },
      })),
    );
  }

  public partialUpdate(idea: PickRequired<Idea, 'id'>): Observable<PickRequired<Idea, 'id'>> {
    return this.bs.put(`iportal/idea-partial/${idea.id}`, idea).pipe(map(() => idea));
  }

  public add(idea: Idea): Observable<DBIdea> {
    return this.bs.post(`iportal/idea`, idea).pipe(
      map((res) => {
        return {
          ...idea,
          id: res.id,
          shortId: res.shortId,
          statusId: res.statusId,
          categoryId: res.categoryId,
          companyId: res.companyId,
          importanceId: res.importanceId,
          innerStatusId: res.innerStatusId,
          creator: res.creator,
        };
      }),
    );
  }

  public delete(ideaId: string): Observable<string> {
    return this.bs.delete(`iportal/idea/${ideaId}`).pipe(map(() => ideaId));
  }

  public bulk(req: IdeasBulkUpdateRequest): Observable<void> {
    return this.bs.post(`iportal/ideas_update`, req).pipe(map(() => void 0));
  }

  public saveIdeaWorkflowStatus(req: IdeaChangeWorkflowStatusRequest): Observable<IdeaChangeWorkflowStatusRequest> {
    return this.bs.put(`iportal/setstatus/${req.ideaId}`, { statusId: req.statusId }).pipe(map(() => req));
  }

  public saveIdeaInnerStatus(req: IdeadChangeInnerStatusRequest): Observable<IdeadChangeInnerStatusRequest> {
    return this.bs
      .put(`iportal/setinnerstatus/${req.ideaId}`, { innerStatusId: req.innerStatusId })
      .pipe(map(() => req));
  }

  public addVote(ideaId: string): Observable<IdeadVotesResponse> {
    return this.bs.post('iportal/vote', { ideaId }).pipe(
      map((res) => {
        return {
          id: ideaId,
          votes: Number.parseInt(res.votes, 10) || 0,
        };
      }),
    );
  }

  public deleteVote(ideaId: string): Observable<IdeadVotesResponse> {
    return this.bs.delete('iportal/vote', { ideaId }).pipe(
      map((res) => {
        return {
          id: ideaId,
          votes: Number.parseInt(res.votes, 10) || 0,
        };
      }),
    );
  }

  public track(ideaId: string): Observable<string> {
    return this.bs.post('iportal/track', { ideaId }).pipe(map(() => ideaId));
  }

  public untrack(ideaId: string): Observable<string> {
    return this.bs.delete('iportal/track', { ideaId }).pipe(map(() => ideaId));
  }

  public archive(ideaId: string): Observable<string> {
    return this.bs.post('iportal/archive', { ideaId }).pipe(map(() => ideaId));
  }

  public unarchive(ideaId: string): Observable<string> {
    return this.bs.delete('iportal/unarchive', { ideaId }).pipe(map(() => ideaId));
  }

  public addTag(req: IdeaChangeTagRequest): Observable<IdeaChangeTagRequest> {
    return this.bs
      .post(`iportal/idea_label/${req.ideaId}`, {
        ideaId: req.ideaId,
        labelId: req.tagId,
      })
      .pipe(map(() => req));
  }

  public deleteTag(req: IdeaChangeTagRequest): Observable<IdeaChangeTagRequest> {
    return this.bs
      .delete(`iportal/idea_label/${req.ideaId}`, {
        ideaId: req.ideaId,
        labelId: req.tagId,
      })
      .pipe(map(() => req));
  }

  public attachFile(req: IdeadAddAttachmentRequest): Observable<IdeadAddAttachmentRequest> {
    return this.bs
      .post(`iportal/idea_attach/${req.ideaId}`, {
        ideaId: req.ideaId,
        fileId: req.attachment.id,
      })
      .pipe(map(() => req));
  }

  public detachFile(req: IdeadDelAttachmentRequest): Observable<IdeadDelAttachmentRequest> {
    return this.bs
      .delete(`iportal/idea_attach/${req.ideaId}`, {
        ideaId: req.ideaId,
        fileId: req.fileId,
      })
      .pipe(map(() => req));
  }

  private mapIdea(idea: any): DBIdea {
    return {
      ...idea,
      votes: Number.parseInt(idea.votes, 10) || 0,
      trend: Number.parseInt(idea.trend, 10) || 0,
      notesCnt: Number.parseInt(idea.notesCnt, 10) || 0,
      linkedCnt: Number.parseInt(idea.linkedCnt, 10) || 0,
      commentsCnt: Number.parseInt(idea.commentsCnt, 10) || 0,
      attaches: Array.isArray(idea.attaches) ? idea.attaches : [],
      custom: Array.isArray(idea.custom) ? idea.custom : [],
      creator: idea.creator ? this.ubs.mapUser(idea.creator) : void 0,
    };
  }
}
