<button
  type="button"
  class="btn clear-disable"
  [disabled]="disabled"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="singleSelectMenu"
>
  <span class="ellipsis">
    {{
      selectedId && companiesMap?.[selectedId]?.name ? companiesMap?.[selectedId]?.name : ('company.select' | craftTerm)
    }}
  </span>
  <craft-icon class="svg-icon text-size-12" name="arrows--arrow-head-down-sm"></craft-icon>
</button>

<mat-menu #singleSelectMenu="matMenu">
  <ng-template matMenuContent="">
    <div *ngIf="hasSearch" class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input
        type="text"
        #searchInput
        (click)="$event.stopPropagation()"
        [placeholder]="searchPlaceholder"
        [formControl]="searchControl"
      />
    </div>
    <div class="menu-scroll menu-content">
      <button
        mat-menu-item=""
        *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
        [ngClass]="{ selected: selectedId === item[idFieldName] }"
        (click)="selectItem(item)"
        craftDisableFocus=""
        type="button"
      >
        {{ item[labelFieldName] }}
      </button>
    </div>
  </ng-template>
</mat-menu>
