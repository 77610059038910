import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { adjustTime } from 'src/app/core/utils/date-utils';

type CalendarDate = Date | string | null | undefined;

@Component({
  selector: 'craft-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftCalendarComponent),
      multi: true,
    },
  ],
})
export class CraftCalendarComponent implements ControlValueAccessor {
  @ViewChild('datepicker', { static: true }) datepicker: MatDatepicker<any>;
  @ViewChild('datepickerFooter', { static: true }) datepickerFooter: ElementRef;

  @Input() public placeholder = 'Set Date';
  @Input() public value: CalendarDate;
  @Output() public change = new EventEmitter<CalendarDate>();

  public onTouched = () => {};
  public onChange = (_: CalendarDate) => {};

  public disabled = false;

  constructor() {}

  public onOpen(): void {
    this.appendFooter();
    this.onTouched();
  }

  public done(): void {
    this.datepicker?.close();
  }

  public clear(): void {
    this.value = void 0;
    this.sendValue(this.value);
    this.datepicker?.close();
  }

  public writeValue(value: CalendarDate): void {
    this.value = value;
  }

  public sendValue(value: CalendarDate) {
    const dt = adjustTime(value);
    this.change.next(dt);
    this.onChange(dt);
  }

  public registerOnChange(fn: (_: CalendarDate) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private appendFooter() {
    const dpContent = document.getElementsByTagName('mat-datepicker-content')[0];
    if (dpContent && this.datepickerFooter?.nativeElement) {
      dpContent.appendChild(this.datepickerFooter.nativeElement);
    }
  }
}
