<button class="btn" type="button" [matMenuTriggerFor]="userMenu">
  <craft-icon class="svg-icon text-size-11" name="subnav--more-b-md"></craft-icon>
</button>
<mat-menu #userMenu="matMenu">
  <ng-template matMenuContent="">
    <button
      type="button"
      *ngIf="showMoveToCompany"
      [matMenuTriggerFor]="companiesSubMenu"
      (click)="$event.stopPropagation()"
      class="mat-mdc-menu-item"
    >
      <craft-icon class="svg-icon text-size-15 mr-11" name="header--sync-md"></craft-icon>
      <span>{{ 'settings.moveToCompany' | craftTerm }}</span>
    </button>
    <button
      type="button"
      *ngIf="showChangeRole"
      [matMenuTriggerFor]="rolesSubMenu"
      (click)="$event.stopPropagation()"
      class="mat-mdc-menu-item"
    >
      <craft-icon class="svg-icon text-size-15 mr-11" name="header--adduser-20"></craft-icon>
      <span>Promote to Administrative User</span>
    </button>
    <button type="button" *ngIf="showDeleteUser" (click)="confirmDeleteUser()" class="mat-mdc-menu-item">
      <craft-icon class="svg-icon text-size-15 mr-11" name="general--delete-md"></craft-icon>
      <span>Delete user</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #companiesSubMenu="matMenu">
  <ng-template matMenuContent="">
    <div class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" (click)="$event.stopPropagation()" placeholder="Search" [formControl]="searchControl" />
    </div>

    <div class="menu-scroll">
      <div
        mat-menu-item=""
        *ngFor="let company of companies$ | async | slice: 0 : 100; trackBy: identify"
        (click)="moveToCompany(company)"
        craftDisableFocus=""
      >
        {{ company.name }}
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #rolesSubMenu="matMenu">
  <ng-template matMenuContent="">
    <div mat-menu-item="" *ngFor="let role of roles" (click)="changeUserRole(role)" craftDisableFocus="">
      {{ role.name | craftTerm }}
    </div>
  </ng-template>
</mat-menu>
