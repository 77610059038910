import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { TerminologyStoreService } from 'src/app/core/store/services/terminology-store.service';

@Injectable({
  providedIn: 'root',
})
export class TerminologyService {
  constructor(
    private translateService: TranslateService,
    private termsStore: TerminologyStoreService,
  ) {}

  public translate(translationId: string | string[], interpolateParams?: StringAnyMap): Observable<any> {
    return this.termsStore.words$.pipe(
      take(1),
      switchMap((words) => {
        return this.translateService.get(translationId, { ...interpolateParams, ...words });
      }),
    );
  }
}
