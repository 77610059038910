import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsResponse, PromoteUserToRoleRequest } from 'src/app/core/models/permissions.models';
import { PortalUser } from 'src/app/core/models/users-models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { UsersBackendService } from 'src/app/core/services/backend/users-backend.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsBackendService {
  constructor(
    private bs: BackendService, //
    private usersBackend: UsersBackendService,
  ) {}

  public getList(portalId: string): Observable<PermissionsResponse> {
    return this.usersBackend.getPortalUsers(portalId).pipe(
      map((users) => {
        const res = users.reduce(
          (res, user) => {
            res.emails.push(user.email);
            res.users[user.email] = user;
            return res;
          },
          { users: {}, emails: [] } as PermissionsResponse,
        );

        return res;
      }),
    );
  }

  public add(user: PortalUser): Observable<PortalUser> {
    return this.bs.post(`portal/admin/${user.portalId}`, user).pipe(map(() => user));
  }

  public update(user: PortalUser): Observable<PortalUser> {
    return this.bs.put(`portal/admin/${user.portalId}`, user).pipe(map(() => user));
  }

  public delete(user: PortalUser): Observable<PortalUser> {
    return this.bs
      .delete(`portal/admin/${user.portalId}`, {
        personId: user.id,
        email: window.encodeURIComponent(user.email),
        portalId: user.portalId,
      })
      .pipe(map(() => user));
  }

  public promoteUserToRole(req: PromoteUserToRoleRequest) {
    return this.bs.put(`iportal/promoteUserToAdmin`, req).pipe(map(() => {}));
  }
}
