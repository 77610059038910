import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { CATEGORIES_DEL_SUCCESS, CategoriesReqDelSuccessAction } from 'src/app/core/store/actions/categories';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import {
  IDEAS_ADD_SUCCESS,
  IDEAS_DEL_SUCCESS,
  IDEAS_SAVE_SUCCESS,
  IdeasReqAddSuccessAction,
  IdeasReqDelSuccessAction,
  IdeasReqSaveSuccessAction,
} from '../actions/ideas';
import { CategoriesStoreService } from '../services/categories-store.service';

type IdeasListChageAction = IdeasReqAddSuccessAction | IdeasReqDelSuccessAction | IdeasReqSaveSuccessAction;

@Injectable()
export class CategoriesEffects {
  constructor(
    private actions$: Actions, //
    private productsStore: ProductsStoreService,
    private categoriesStore: CategoriesStoreService,
  ) {}

  public ideasListChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasListChageAction | CategoriesReqDelSuccessAction>(
          IDEAS_ADD_SUCCESS, //
          IDEAS_DEL_SUCCESS,
          IDEAS_SAVE_SUCCESS,
          CATEGORIES_DEL_SUCCESS,
        ),
        switchMap(() => {
          return this.productsStore.activeData$;
        }),
        map((activaData) => activaData.selectedId),
        filter((selectedId): selectedId is string => !!selectedId),
        switchMap((workspaceId) => {
          return this.categoriesStore.loadList(workspaceId);
        }),
      );
    },
    { dispatch: false },
  );
}
