import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'craft-user-icon',
  templateUrl: './craft-user-icon.component.html',
  styleUrls: ['./craft-user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftUserIconComponent implements OnInit {
  @Input() public size: 'small' | 'medium' | 'big' = 'medium';
  @Input() public user?: any;

  constructor() {}

  public ngOnInit() {
    if (typeof this.user === 'string') {
      try {
        this.user = JSON.parse(this.user);
      } catch (e) {}
    }
  }
}
