import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { IdeasVisibility } from 'src/app/core/enums/IdeaVisibility';
import { DBCategory } from 'src/app/core/models/categories.models';
import { Portal } from 'src/app/core/models/portal.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { CategoryPrivacySettingsComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-settings.component';

@Component({
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss'],
})
export class CategoryEditComponent extends Destroyer implements OnInit {
  public isSaving = false;
  public isSubmitted = false;

  public readonly form = new FormGroup({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    description: new FormControl<string>('', { nonNullable: true }),
    companies: new FormControl<readonly string[]>([], { nonNullable: true }),
    isPrivate: new FormControl<boolean>(false, { nonNullable: true }),
    isDefault: new FormControl<boolean>(false, { nonNullable: true }),
    ideaVisibility: new FormControl<IdeasVisibility>(IdeasVisibility.visibleToAllUsers, { nonNullable: true }),
  });

  constructor(
    private dialog: MatDialog,
    private portalStore: PortalStoreService,
    private categoriesStore: CategoriesStoreService,
    private dialogRef: MatDialogRef<CategoryEditComponent, DBCategory>,
    @Inject(MAT_DIALOG_DATA) public category: DBCategory,
  ) {
    super();
  }

  public ngOnInit() {
    const portal = this.portalStore.portalSnapshot as Portal;

    if (portal.categoryDescriptionMandatory) {
      this.form.controls['description'].setValidators(Validators.required);
    }

    this.form.patchValue({ ...this.category }, { emitEvent: false });
  }

  public close() {
    this.dialogRef.close();
  }

  public showPrivacyDialog() {
    this.openPrivacyDialog(this.category)
      .pipe(
        filter((category): category is DBCategory => !!category),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.form.patchValue(category, { emitEvent: false });
      });
  }

  private openPrivacyDialog(category: DBCategory): Observable<DBCategory | undefined> {
    return this.dialog
      .open(CategoryPrivacySettingsComponent, {
        backdropClass: 'night-backdrop',
        data: category,
      })
      .afterClosed();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    const category: DBCategory = {
      ...this.category,
      ...this.form.value,
    };

    this.isSaving = true;
    this.categoriesStore
      .update(category)
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(() => {
        this.isSaving = false;
        this.dialogRef.close(category);
      });
  }
}
