import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CRAFT_PRELOADER_OPTIONS, CraftPreloaderComponent, CraftPreloaderOptions } from './craft-preloader.component';

@Injectable({
  providedIn: 'root',
})
export class CraftPreloaderService {
  private counter = 0;
  private overlayRef: OverlayRef | null;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private router: Router,
  ) {
    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd || //
            e instanceof NavigationCancel ||
            e instanceof NavigationError,
        ),
      )
      .subscribe(() => this.hide(true));
  }

  public show() {
    this.counter++;
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
      const preloaderPortal = new ComponentPortal(CraftPreloaderComponent, null, this.createPreloaderOptions());
      this.overlayRef.attach(preloaderPortal);
    }
  }

  public hide(force = false) {
    if (force) {
      this.counter = 0;
    }

    if (this.counter > 0) {
      this.counter--;
    }

    if (this.counter === 0 && this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private createPreloaderOptions(): PortalInjector {
    const options: CraftPreloaderOptions = { color: true, customClass: 'fixed lg' };

    const injectorTokens = new WeakMap();
    injectorTokens.set(CRAFT_PRELOADER_OPTIONS, options);
    return new PortalInjector(this.injector, injectorTokens);
  }
}
