import { formatDate } from '@angular/common';
import { PeriodEnum } from 'src/app/core/models/period';

export const adjustTime = <T extends Date | string | number | null | undefined>(date: T): T => {
  if (!date) return date as T;

  const dt = date instanceof Date ? date : new Date(date);
  const utc = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 12, 0, 0, 0);

  if (typeof date === 'number') return utc as T;

  const utcDate = new Date(utc);
  if (date instanceof Date) return utcDate as T;

  return utcDate.toJSON() as T;
};

export function toUTCDate(date: Date): number {
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
}

export function toLocalDate(utc: Date | string | number): Date {
  const result = new Date(utc);
  result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
  return result;
}

export const getUTCDateRangeByPeriod = (period: PeriodEnum): [number, number] => {
  const now = new Date();
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

  switch (period) {
    case PeriodEnum.lastYear:
      return [
        toUTCDate(new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())), //
        toUTCDate(end),
      ];
    case PeriodEnum.last3Months:
      return [
        toUTCDate(new Date(now.getFullYear(), now.getMonth() - 3, now.getDate())), //
        toUTCDate(end),
      ];
    case PeriodEnum.lastMonth:
      return [
        toUTCDate(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())), //
        toUTCDate(end),
      ];
    case PeriodEnum.lastWeek:
      return [
        toUTCDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)), //
        toUTCDate(end),
      ];
    case PeriodEnum.today:
      return [
        toUTCDate(new Date(now.getFullYear(), now.getMonth(), now.getDate())), //
        toUTCDate(end),
      ];
    case PeriodEnum.custom:
      return [0, 0];
  }
};

export const getUTCDateRangeByDate = (start: Date, end: Date): [number, number] => {
  end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
  return [toUTCDate(start), toUTCDate(end)];
};

export const formatPeriod = (start?: string | number | Date | null, end?: string | number | Date | null): string => {
  if (start && end) {
    const s: number =
      typeof start === 'string' //
        ? new Date(start).getTime()
        : start instanceof Date
        ? start.getTime()
        : start;

    const e: number =
      typeof end === 'string' //
        ? new Date(end).getTime()
        : end instanceof Date
        ? end.getTime()
        : end;

    return e - s >= 24 * 60 * 60 * 1000 // ms in day
      ? `${formatDate(start, 'MMM dd yyyy', 'en')} - ${formatDate(end, 'MMM dd yyyy', 'en')}`
      : `${formatDate(end, 'MMM dd yyyy', 'en')}`;
  }

  const dt = start || end;
  if (dt) {
    return formatDate(dt, 'MMM dd yyyy', 'en');
  }

  return '';
};
