import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { UserRole } from 'src/app/core/enums/UserRole';
import { StringAnyMap, StringTMap } from 'src/app/core/models/common.models';
import { CompanyUser, PortalUser, PublicUser, User } from 'src/app/core/models/users-models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { authorize } from 'src/app/core/utils/app-utils';
import { getSortByString } from 'src/app/core/utils/sort-utils';
import { getFullName } from 'src/app/core/utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class UsersBackendService {
  constructor(private bs: BackendService) {}

  public getCraftUsersMap(portalId: string): Observable<StringTMap<PortalUser>> {
    return this.bs.get('portal/getusers/', { portalId }).pipe(
      map((teams) => {
        return this.getUsersFromTeams(portalId, teams || {});
      }),
    );
  }

  public searchInCreators(search?: string, limit = 300, page = 1): Observable<PortalUser[]> {
    const req: any = { limit, page };
    if (search) {
      req.search = search;
    }

    return this.bs.get('iportal/creators/', req).pipe(map((res) => res.list.map((user: any) => this.mapUser(user))));
  }

  public searchInAdmins(search: string, otherParams = {}, limit = 300, page = 1): Observable<PortalUser[]> {
    const req: any = { ...otherParams, limit, page };
    if (search) {
      req.search = search;
    }

    return this.bs.get('portal/find_admin/', req).pipe(map((res) => res.list));
  }

  public resolveLikes(ideaId: string, limit = 100): Observable<PortalUser[]> {
    return this.bs.get('iportal/vote', { ideaId, limit }).pipe(map((result) => result.list as PortalUser[]));
  }

  public getPortalUsers(portalId: string): Observable<PortalUser[]> {
    return this.bs.get('portal/admin/', { portalId }).pipe(
      map((res) => {
        const invites: PortalUser[] = (res.invites || []).map((inv: any) => this.mapInvite(inv));
        const users: PortalUser[] = (res.list || []).map((user: any) => this.mapUser(user, portalId));
        return invites.concat(users);
      }),
    );
  }

  public getNotesMentions(portalId: string): Observable<PortalUser[]> {
    return this.bs.get('portal/admin/', { portalId, isNotes: true }).pipe(
      map((res) => {
        const users: PortalUser[] = (res.list || []).map((user: any) => this.mapUser(user, portalId));
        return users.sort(getSortByString('fullName'));
      }),
    );
  }

  public getCommentsMentions(portalId: string, ideaId: string, query: string): Observable<PortalUser[]> {
    return this.bs.get('iportal/available_users', { ideaId, query }).pipe(
      map((res: any) => {
        const list: PortalUser[] = (res.list || []).map((user: any) => this.mapUser(user, portalId));
        return list;
      }),
    );
  }

  public sendInvite(email: string): Observable<void> {
    return this.bs.post('portal/resend_invite', { email });
  }

  public mapUser(user: any, portalId?: string): PortalUser | CompanyUser | PublicUser {
    const id = user.userId || user.id;
    const isPublicUser = !!user.isPublicUser;
    const iportalRole = Number.parseInt(user.iportalRole, 10);

    let userType: User['userType'];
    if (isPublicUser) {
      userType = 'public';
    } else if (authorize(AccessLevel.Reviewer, iportalRole)) {
      userType = 'admin';
    } else {
      userType = 'company';
    }

    return {
      ...user,
      id,
      userType,
      portalId: portalId || user.portalId,
      created: new Date(user.addedDt),
      iportalRole: iportalRole || UserRole.Anonymous,
      fullName: getFullName(user),
      feedbacksCount: Number.parseInt(user.feedbacksCount, 10) || 0,
      confirmed: !!id,
    };
  }

  public mapInvite(inv: any): PortalUser {
    return {
      id: void 0,
      portalId: inv.portalId,
      email: inv.email,
      iportalRole: Number.parseInt(inv.permission, 10) || 1,
      created: new Date(inv.invitedDt),
      feedbacksCount: 0,
      userType: 'admin',
    };
  }

  private getUsersFromTeams(portalId: string, productTeams: StringAnyMap) {
    return Object.keys(productTeams)
      .reduce((res, productId) => {
        productTeams[productId].forEach((team: any) =>
          team.users.forEach((user: any) => {
            const puser: PortalUser = {
              ...user,
              portalId,
              color: team.color,
              id: user.userId,
              fullName: user.fullName || getFullName(user),
              userType: 'admin',
              iportalRole: UserRole.Reviewer,
            };
            res.push(puser);
          }),
        );
        return res;
      }, [] as PortalUser[])
      .sort(getSortByString('fullName'))
      .reduce((res, user: PortalUser) => {
        res[user.id!] = user;
        return res;
      }, {} as StringTMap<PortalUser>);
  }
}
