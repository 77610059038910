import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StaticFilterType } from 'src/app/core/enums/StaticFilterType';
import { StringStringMap, StringTMap } from 'src/app/core/models/common.models';
import { ServerFilterValue } from 'src/app/core/models/filters.models';
import { PeriodEnum } from 'src/app/core/models/period';
import {
  FILTERS_APPLY,
  FILTERS_NEXT_PAGE,
  FILTERS_RESET,
  FILTERS_RESET_PAGE,
  FiltersAction,
} from 'src/app/core/store/actions/filters';

export type FiltersState = {
  readonly limit: number;
  readonly page: number;
  readonly sort: string;
  readonly staticFilter?: StaticFilterType;
  readonly categoryId?: string;
  readonly search?: string;
  readonly period?: PeriodEnum;
  readonly createdStart?: number;
  readonly createdEnd?: number;
  readonly workflowStatuses: readonly string[];
  readonly innerStatuses: readonly string[];
  readonly importances: readonly string[];
  readonly items: readonly string[];
  readonly companies: readonly string[];
  readonly categories: readonly string[];
  readonly postedBy: readonly string[];
  readonly labels: readonly string[];
  readonly custom: Readonly<StringTMap<ServerFilterValue>>;
};

export type FilterProp = keyof FiltersState | `custom.${string}`;
export type FilterSearchParams = { query?: string; parentId?: string };
export type FilterDataSourceFn<T = any> = (params?: FilterSearchParams) => Observable<readonly T[]>;
export type FilterDataSource<T = any> = Observable<T[]> | Observable<StringTMap<T>> | FilterDataSourceFn<T>;
export type AdditionalFilter<T = any> = {
  readonly control?: string;
  readonly menuText: string;
  readonly isAutoShown?: boolean;
  readonly isExists?: boolean;
  readonly buttonText?: StringStringMap;
  readonly dataSource?: FilterDataSource<T>;
  readonly searchParams?: FilterSearchParams;
  readonly uniqName: FilterProp;
  readonly shownDate?: number;
  readonly idFieldName?: string;
  readonly labelFieldName?: string;
};

export const initialState: FiltersState = {
  limit: 50,
  page: 1,
  sort: '-createdDt',
  workflowStatuses: [],
  innerStatuses: [],
  importances: [],
  items: [],
  companies: [],
  categories: [],
  postedBy: [],
  labels: [],
  custom: {},
};

export function reducer(state = initialState, action: FiltersAction): FiltersState {
  switch (action.type) {
    case FILTERS_APPLY: {
      const req = action.payload;
      const {
        search,
        sort = initialState.sort,
        workflowStatuses = initialState.workflowStatuses,
        innerStatuses = initialState.innerStatuses,
        importances = initialState.importances,
        items = initialState.items,
        categories = initialState.categories,
        companies = initialState.companies,
        postedBy = initialState.postedBy,
        labels = initialState.labels,
        period = initialState.period,
        createdStart = initialState.createdStart,
        createdEnd = initialState.createdEnd,
        custom = initialState.custom,
      } = req;

      let staticFilter = initialState.staticFilter;
      let categoryId = initialState.categoryId;

      if (req.categoryId) {
        staticFilter = void 0;
        categoryId = req.categoryId;
      } else if (req.staticFilter) {
        staticFilter = (StaticFilterType as any)[req.staticFilter];
        categoryId = void 0;
      }

      return {
        ...initialState,
        categoryId,
        staticFilter,
        sort,
        search,
        workflowStatuses,
        innerStatuses,
        importances,
        items,
        categories,
        companies,
        postedBy,
        labels,
        period,
        createdStart,
        createdEnd,
        custom,
      };
    }

    case FILTERS_RESET: {
      return {
        ...initialState,
      };
    }

    case FILTERS_RESET_PAGE: {
      return {
        ...state,
        page: initialState.page,
      };
    }

    case FILTERS_NEXT_PAGE: {
      return { ...state, page: state.page + 1 };
    }

    default:
      return state;
  }
}

const getPage = (state: FiltersState) => state.page;
const getSort = (state: FiltersState) => state.sort;
const getLimit = (state: FiltersState) => state.limit;
const getCategoryId = (state: FiltersState) => state.categoryId;
const getStaticFilter = (state: FiltersState) => state.staticFilter;

export const getFiltersStateSelector = createFeatureSelector<FiltersState>('filtersState');
export const getFiltersSortSelector = createSelector(getFiltersStateSelector, getSort);
export const getFiltersPageSelector = createSelector(getFiltersStateSelector, getPage);
export const getFiltersLimitSelector = createSelector(getFiltersStateSelector, getLimit);
export const getCategoryIdSelector = createSelector(getFiltersStateSelector, getCategoryId);
export const getStaticFilterSelector = createSelector(getFiltersStateSelector, getStaticFilter);
