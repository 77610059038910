<form *ngIf="!newIdea.id; else thanks" novalidate="" [formGroup]="form" (submit)="submit()" class="new-idea-dialog">
  <ng-container *ngIf="portalStore.portal$ | async as portal">
    <div class="dialog-title">
      <div class="left">
        <h1>{{ 'common.text2' | craftTerm }}</h1>
        <mat-error *ngIf="isSubmitted && form.invalid" class="general-validation">
          <craft-icon class="svg-icon" name="general--error"></craft-icon> Please fill {{ formErrors }} required fields
        </mat-error>
      </div>
      <div class="right">
        <craft-attach-select-button
          (fileSelect)="attachFiles($event)"
          class="attach-btn"
          matTooltip="Attach file"
          matTooltipPosition="above"
        >
          <span class="text-size-14">Attach</span>
          <craft-icon class="svg-icon text-size-11 ml-4" name="formatbar--attach-sm"></craft-icon>
        </craft-attach-select-button>
        <button type="button" (click)="close()" class="btn btn-close text-size-15">
          <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
        </button>
      </div>
    </div>

    <div mat-dialog-content="" #scrollConteiner class="dialog-content">
      <div class="header-controls">
        <div class="overflow-hidden d-flex align-items-center gap-8">
          <span class="uppercase dropdown-title">importance:</span>
          <craft-importances-select
            formControlName="importanceId"
            [unselectMode]="true"
            class="d-contents"
          ></craft-importances-select>
        </div>

        <div class="overflow-hidden d-flex align-items-center">
          <mat-error *ngIf="isSubmitted && form.controls['categoryId'].invalid">
            {{ 'common.text15' | craftTerm }}
          </mat-error>
          <craft-idea-category-dropdown
            class="ellipsis"
            formControlName="categoryId"
            (enterPressed)="nameInput.focus()"
            (selectNew)="selectNewCategory($event)"
          >
          </craft-idea-category-dropdown>
        </div>
      </div>

      <div class="mt-10">
        <mat-error *ngIf="isSubmitted && form.controls['name'].invalid">
          {{ 'common.text5' | craftTerm }}
        </mat-error>
        <p class="f-title">
          {{ 'common.ftitle' | craftTerm }}
        </p>
        <textarea
          class="textarea-input"
          [attr.placeholder]="'common.text6' | craftTerm"
          formControlName="name"
          maxlength="255"
          craftAutoresize=""
          craftAutoFocus=""
          [disableLineBreak]="true"
          #nameInput
        ></textarea>
      </div>

      <craft-idea-custom-controls
        [isFormSubmitted]="isSubmitted"
        [formArray]="form.get('custom')"
      ></craft-idea-custom-controls>

      <craft-idea-attachments-progress formControlName="attaches"></craft-idea-attachments-progress>
    </div>

    <div mat-dialog-actions="" class="dialog-actions text-size-12">
      <div
        *craftPermissions="'AccountOwner'; restricted: true; operator: 'and'; else: emptyDiv"
        class="d-flex align-items-center gap-8 ellipsis"
      >
        <span class="uppercase dropdown-title shrink-0">Post as</span>
        <craft-users-search-select formControlName="creator" class="d-flex ellipsis"></craft-users-search-select>
      </div>

      <ng-container *craftCompanyPermission="'AccountOwner'; else emptyDiv">
        <div class="d-flex align-items-center gap-8 maxw-50 ellipsis">
          <span class="uppercase dropdown-title">{{ 'company.company' | craftTerm }}:</span>
          <craft-companies-select
            formControlName="companyId"
            [unselectMode]="true"
            [disabled]="form.get('creator')?.value?.userType !== 'admin'"
            class="d-flex ellipsis"
          ></craft-companies-select>
        </div>
      </ng-container>

      <button
        class="btn btn-blue dialog-btn shrink-0 submit-btn"
        type="submit"
        mat-button=""
        [disabled]="isSending || isAnyUploading"
      >
        Submit
      </button>
    </div>
  </ng-container>
</form>

<ng-template #emptyDiv>
  <div></div>
</ng-template>

<ng-template #thanks>
  <div class="new-idea-dialog thanks-dialog">
    <div class="thanks-close">
      <button type="button" (click)="close()" class="btn btn-close text-size-15">
        <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
      </button>
    </div>

    <h1 class="dialog-title">Thank you for helping us improve!</h1>

    <div class="dialog-content" mat-dialog-content="">
      <img src="assets/img/thank-you.svg" alt="" />
    </div>

    <div mat-dialog-actions="" class="dialog-actions d-flex justify-center">
      <button class="btn btn dialog-btn btn-border shrink-0" type="button" (click)="redirectToIdea()" mat-button="">
        {{ 'common.text40' | craftTerm }}
      </button>

      <button class="btn btn-blue dialog-btn shrink-0" type="button" (click)="addAnotherFeedback()" mat-button="">
        {{ 'common.text41' | craftTerm }}
      </button>
    </div>
  </div>
</ng-template>
