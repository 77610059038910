import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { concatWith, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PortalUser } from 'src/app/core/models/users-models';
import { UsersBackendService } from 'src/app/core/services/backend/users-backend.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { BaseSingleSelectComponent } from 'src/app/shared/components/craft-single-select/craft-single-select.component';

@Component({
  selector: 'craft-users-search-select',
  templateUrl: './craft-users-search-select.component.html',
  styleUrls: ['./craft-users-search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftUsersSearchSelectComponent),
      multi: true,
    },
  ],
})
export class CraftUsersSearchSelectComponent extends BaseSingleSelectComponent implements OnInit {
  public isPreloaderShow = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    protected portalStore: PortalStoreService,
    protected usersService: UsersBackendService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.filteredItems = of('').pipe(
      concatWith(this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged())),
      tap((term) => {
        this.isPreloaderShow = !!term;
      }),
      switchMap((searchText) => this.usersService.searchInCreators(searchText)),
      tap(() => {
        this.isPreloaderShow = false;
      }),
    );

    this.searchControl.setValue(void 0);
  }

  public writeValue(value: PortalUser) {
    this.selectedId = value ? value.id : void 0;
    this.selectedItem = value;
  }

  public selectItem(user: PortalUser) {
    this.changeSelectedItem(user);
    this.onChange(this.selectedItem);
  }
}
