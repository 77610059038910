<button
  type="button"
  class="btn-add-category"
  [hidden]="showAutoComplete"
  (click)="showInput()"
  [ngSwitch]="!!selectedCategory"
>
  <span *ngSwitchCase="false">
    <craft-icon class="svg-icon text-size-11 mr-4" name="general--plus-sm"></craft-icon>
    {{ 'settings.text18' | craftTerm }}
  </span>
  <span *ngSwitchCase="true" class="selected ellipsis">
    <span class="ellipsis">{{ selectedCategory?.name }}</span>
    <craft-icon class="svg-icon text-size-11" name="arrows--arrow-head-down-sm"></craft-icon>
  </span>
</button>

<ng-container *ngIf="showAutoComplete">
  <ng-container *ngIf="categories$ | async as categories">
    <input
      #searchInput
      craftAutoFocus=""
      [focusDelay]="100"
      [matAutocomplete]="auto"
      [formControl]="searchControl"
      (keydown.enter)="onEnterKey($event, categories)"
      type="text"
      aria-label="Number"
      placeholder="Search or add new"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="selectCategory($event.option.value)"
      (closed)="menuClosed()"
      class="categories-autocomplete"
    >
      <mat-option
        class="category-option"
        *ngIf="searchInput.value && categories.length === 0"
        [value]="{ name: searchInput.value }"
      >
        <b>Create new:</b> {{ searchInput.value }}
      </mat-option>

      <mat-option
        class="category-option"
        *ngFor="let category of categories | slice: 0 : 100"
        [value]="category"
        [matTooltip]="category.name"
      >
        <span [ngSwitch]="!!category.id">
          {{ category.name }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</ng-container>
