<form novalidate="" [formGroup]="form" (submit)="submit()" class="category-dialog">
  <div class="dialog-title">
    <h1>{{ 'common.text28' | craftTerm }}</h1>

    <div class="d-flex align-items-center gap-8">
      <ng-container *ngIf="!form.value?.isDefault">
        <craft-category-dialog-menu
          *craftPermissions="'ProcessOwner'; restricted: true; operator: 'and'"
          (privacySelect)="showPrivacyDialog()"
        >
        </craft-category-dialog-menu>
      </ng-container>
      <button type="button" (click)="close()" class="btn text-size-15">
        <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content="" class="dialog-content">
    <div class="title-wrap">
      <textarea
        class="new-category-title textarea-input"
        [attr.placeholder]="'common.text16' | craftTerm"
        formControlName="name"
        maxlength="255"
        craftAutoresize=""
        [disableLineBreak]="true"
        #nameInput
      ></textarea>
      <mat-error *ngIf="isSubmitted && form.controls['name'].invalid">
        {{ 'common.text13' | craftTerm }}
      </mat-error>
    </div>

    <craft-text-editor
      class="new-category-desc textarea-input w-100"
      formControlName="description"
      [maxlength]="10000"
      placeholder="Add short description"
    >
    </craft-text-editor>
    <mat-error *ngIf="isSubmitted && form.controls['description'].invalid">
      Please enter description – it's required.
    </mat-error>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <button class="btn dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn" type="submit" mat-button="" [disabled]="isSaving">Save</button>
  </div>
</form>
