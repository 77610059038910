import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { ImportancesStoreService } from 'src/app/core/store/services/importances-store.service';
import { BaseSingleSelectComponent } from '../craft-single-select/craft-single-select.component';

@Component({
  selector: 'craft-importances-select',
  templateUrl: './craft-importances-select.component.html',
  styleUrls: ['./craft-importances-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftImportancesSelectComponent),
      multi: true,
    },
  ],
})
export class CraftImportancesSelectComponent extends BaseSingleSelectComponent implements OnInit, OnChanges {
  @Input() public items: Readonly<StringAnyMap>;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private importancesStore: ImportancesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.importancesStore.map$.pipe(takeUntil(this.destroy$)).subscribe((importances) => {
      this.setItems(importances, this.selectedId);
    });
  }
}
