import { Action } from '@ngrx/store';
import { ErrorResponse, PickRequired } from 'src/app/core/models/common.models';
import {
  DBIdea,
  Idea,
  IdeaChangeImportanceRequest,
  IdeaChangeTagRequest,
  IdeaChangeWorkflowStatusRequest,
  IdeadAddAttachmentRequest,
  IdeadChangeInnerStatusRequest,
  IdeadDelAttachmentRequest,
  IdeadLinkItemRequest,
  IdeadVotesResponse,
  IdeasBulkUpdateRequest,
  IdeasListResponse,
  IdeaUpdateLinkedItemsCountRequest,
} from 'src/app/core/models/idea.models';
import { IdeasStateStatus } from 'src/app/core/store/reducers/ideas';

export const IDEAS_SET_LIST = '[Ideas] Set list';
export const IDEAS_SET_PAGE = '[Ideas] Set page';
export const IDEAS_SET_STATUS = '[Ideas] Set status';
export const IDEAS_UPDATE_ONE = '[Ideas] Update one';
export const IDEAS_SELECT_BY_ID = '[Ideas] Set selected by id';
export const IDEAS_UPDATE_LINKED_ITEMS_COUNT = '[Ideas] Update linked items count';

export const IDEAS_ADD = '[Ideas] add';
export const IDEAS_ADD_ERROR = '[Ideas] add error';
export const IDEAS_ADD_SUCCESS = '[Ideas] add success';

export const IDEAS_SAVE = '[Ideas] save';
export const IDEAS_SAVE_ERROR = '[Ideas] save error';
export const IDEAS_SAVE_SUCCESS = '[Ideas] save success';

export const IDEAS_DEL = '[Ideas] del';
export const IDEAS_DEL_ERROR = '[Ideas] del error';
export const IDEAS_DEL_SUCCESS = '[Ideas] del success';

export const IDEAS_ARCHIVE = '[Ideas] archive';
export const IDEAS_ARCHIVE_ERROR = '[Ideas] archive error';
export const IDEAS_ARCHIVE_SUCCESS = '[Ideas] archive success';

export const IDEAS_UNARCHIVE = '[Ideas] unarchive';
export const IDEAS_UNARCHIVE_ERROR = '[Ideas] unarchive error';
export const IDEAS_UNARCHIVE_SUCCESS = '[Ideas] unarchive success';

export const IDEAS_ADD_TAG = '[Ideas] add tag';
export const IDEAS_ADD_TAG_ERROR = '[Ideas] add tag error';
export const IDEAS_ADD_TAG_SUCCESS = '[Ideas] add tag success';

export const IDEAS_BULK_UPDATE = '[Ideas] bulk update';
export const IDEAS_BULK_UPDATE_ERROR = '[Ideas] bulk update error';
export const IDEAS_BULK_UPDATE_SUCCESS = '[Ideas] bulk update success';

export const IDEAS_DEL_TAG = '[Ideas] del tag';
export const IDEAS_DEL_TAG_ERROR = '[Ideas] del tag error';
export const IDEAS_DEL_TAG_SUCCESS = '[Ideas] del tag success';

export const IDEAS_VOTE = '[Ideas] vote';
export const IDEAS_VOTE_ERROR = '[Ideas] vote error';
export const IDEAS_VOTE_SUCCESS = '[Ideas] vote change success';

export const IDEAS_DEL_VOTE = '[Ideas] del vote';
export const IDEAS_DEL_VOTE_ERROR = '[Ideas] del vote error';
export const IDEAS_DEL_VOTE_SUCCESS = '[Ideas] del vote success';

export const IDEAS_TRACK = '[Ideas] track';
export const IDEAS_TRACK_ERROR = '[Ideas] track error';
export const IDEAS_TRACK_SUCCESS = '[Ideas] track success';

export const IDEAS_UNTRACK = '[Ideas] untrack';
export const IDEAS_UNTRACK_ERROR = '[Ideas] untrack error';
export const IDEAS_UNTRACK_SUCCESS = '[Ideas] untrack success';

export const IDEAS_LINK_ITEM = '[Ideas] link item';
export const IDEAS_LINK_ITEM_ERROR = '[Ideas] link item error';
export const IDEAS_LINK_ITEM_SUCCESS = '[Ideas] link item success';

export const IDEAS_UNLINK_ITEM = '[Ideas] unlink item';
export const IDEAS_UNLINK_ITEM_ERROR = '[Ideas] unlink item error';
export const IDEAS_UNLINK_ITEM_SUCCESS = '[Ideas] unlink item success';

export const IDEAS_SAVE_IMPORTANCE = '[Ideas] save importance';
export const IDEAS_SAVE_IMPORTANCE_ERROR = '[Ideas] save importance error';
export const IDEAS_SAVE_IMPORTANCE_SUCCESS = '[Ideas] save importance success';

export const IDEAS_SAVE_WORKFLOW_STATUS = '[Ideas] save workflow status';
export const IDEAS_SAVE_WORKFLOW_STATUS_ERROR = '[Ideas] save workflow status error';
export const IDEAS_SAVE_WORKFLOW_STATUS_SUCCESS = '[Ideas] save workflow status success';

export const IDEAS_SAVE_INNER_STATUS = '[Ideas] save inner status';
export const IDEAS_SAVE_INNER_STATUS_ERROR = '[Ideas] save inner status error';
export const IDEAS_SAVE_INNER_STATUS_SUCCESS = '[Ideas] save inner status success';

export const IDEAS_ATTACH_FILE = '[Ideas] attach file';
export const IDEAS_ATTACH_FILE_ERROR = '[Ideas] attach file error';
export const IDEAS_ATTACH_FILE_SUCCESS = '[Ideas] attach file success';

export const IDEAS_DETACH_FILE = '[Ideas] detach file';
export const IDEAS_DETACH_FILE_ERROR = '[Ideas] detach file error';
export const IDEAS_DETACH_FILE_SUCCESS = '[Ideas] detach file success';

export const IDEAS_PARTIAL_UPDATE = '[Ideas] partial update';
export const IDEAS_PARTIAL_UPDATE_ERROR = '[Ideas] partial update error';
export const IDEAS_PARTIAL_UPDATE_SUCCESS = '[Ideas] partial update success';

// MISC
export class IdeasSetListAction implements Action {
  readonly type = IDEAS_SET_LIST;
  constructor(public payload: IdeasListResponse) {}
}
export class IdeasSetPageAction implements Action {
  readonly type = IDEAS_SET_PAGE;
  constructor(public payload: IdeasListResponse) {}
}
export class IdeasSetStatusAction implements Action {
  readonly type = IDEAS_SET_STATUS;
  constructor(public payload: IdeasStateStatus) {}
}
export class IdeasUpdateOneAction implements Action {
  readonly type = IDEAS_UPDATE_ONE;
  constructor(public payload: Partial<Idea> & { readonly id: string }) {}
}
export class IdeasSetSelectedByIdAction implements Action {
  readonly type = IDEAS_SELECT_BY_ID;
  constructor(public payload: string | undefined) {}
}
export class IdeasUpdateLinkedItemsCountAction implements Action {
  readonly type = IDEAS_UPDATE_LINKED_ITEMS_COUNT;
  constructor(public payload: IdeaUpdateLinkedItemsCountRequest) {}
}

// ADD
export class IdeasReqAddAction implements Action {
  readonly type = IDEAS_ADD;
  constructor(public payload: Idea) {}
}
export class IdeasReqAddErrorAction implements Action {
  readonly type = IDEAS_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqAddSuccessAction implements Action {
  readonly type = IDEAS_ADD_SUCCESS;
  constructor(public payload: DBIdea) {}
}

// SAVE
export class IdeasReqSaveAction implements Action {
  readonly type = IDEAS_SAVE;
  constructor(public payload: DBIdea) {}
}
export class IdeasReqSaveErrorAction implements Action {
  readonly type = IDEAS_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqSaveSuccessAction implements Action {
  readonly type = IDEAS_SAVE_SUCCESS;
  constructor(public payload: DBIdea) {}
}

// DELETE
export class IdeasReqDelAction implements Action {
  readonly type = IDEAS_DEL;
  constructor(public payload: string) {}
}
export class IdeasReqDelErrorAction implements Action {
  readonly type = IDEAS_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqDelSuccessAction implements Action {
  readonly type = IDEAS_DEL_SUCCESS;
  constructor(public payload: string) {}
}

// BULK UPDATE
export class IdeasBulkUpdateAction implements Action {
  readonly type = IDEAS_BULK_UPDATE;
  constructor(public payload: IdeasBulkUpdateRequest) {}
}
export class IdeasBulkUpdateErrorAction implements Action {
  readonly type = IDEAS_BULK_UPDATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasBulkUpdateSuccessAction implements Action {
  readonly type = IDEAS_BULK_UPDATE_SUCCESS;
  constructor(public payload: IdeasBulkUpdateRequest) {}
}

// ARCHIVE
export class IdeasReqArchiveAction implements Action {
  readonly type = IDEAS_ARCHIVE;
  constructor(public payload: string) {}
}
export class IdeasReqArchiveErrorAction implements Action {
  readonly type = IDEAS_ARCHIVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqArchiveSuccessAction implements Action {
  readonly type = IDEAS_ARCHIVE_SUCCESS;
  constructor(public payload: string) {}
}

// UNARCHIVE
export class IdeasReqUnArchiveAction implements Action {
  readonly type = IDEAS_UNARCHIVE;
  constructor(public payload: string) {}
}
export class IdeasReqUnArchiveErrorAction implements Action {
  readonly type = IDEAS_UNARCHIVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqUnArchiveSuccessAction implements Action {
  readonly type = IDEAS_UNARCHIVE_SUCCESS;
  constructor(public payload: string) {}
}

// ADD TAG
export class IdeasReqAddTagAction implements Action {
  readonly type = IDEAS_ADD_TAG;
  constructor(public payload: IdeaChangeTagRequest) {}
}
export class IdeasReqAddTagErrorAction implements Action {
  readonly type = IDEAS_ADD_TAG_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqAddTagSuccessAction implements Action {
  readonly type = IDEAS_ADD_TAG_SUCCESS;
  constructor(public payload: IdeaChangeTagRequest) {}
}

// DELETE TAG
export class IdeasReqDelTagAction implements Action {
  readonly type = IDEAS_DEL_TAG;
  constructor(public payload: IdeaChangeTagRequest) {}
}
export class IdeasReqDelTagErrorAction implements Action {
  readonly type = IDEAS_DEL_TAG_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqDelTagSuccessAction implements Action {
  readonly type = IDEAS_DEL_TAG_SUCCESS;
  constructor(public payload: IdeaChangeTagRequest) {}
}

// VOTE
export class IdeasReqVoteAction implements Action {
  readonly type = IDEAS_VOTE;
  constructor(public payload: string) {}
}
export class IdeasReqVoteErrorAction implements Action {
  readonly type = IDEAS_VOTE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqVoteSuccessAction implements Action {
  readonly type = IDEAS_VOTE_SUCCESS;
  constructor(public payload: IdeadVotesResponse) {}
}

// DISLIKE
export class IdeasReqDelVoteAction implements Action {
  readonly type = IDEAS_DEL_VOTE;
  constructor(public payload: string) {}
}
export class IdeasReqDelVoteErrorAction implements Action {
  readonly type = IDEAS_DEL_VOTE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqDelVoteSuccessAction implements Action {
  readonly type = IDEAS_DEL_VOTE_SUCCESS;
  constructor(public payload: IdeadVotesResponse) {}
}

// TRACK
export class IdeasReqTrackAction implements Action {
  readonly type = IDEAS_TRACK;
  constructor(public payload: string) {}
}
export class IdeasReqTrackErrorAction implements Action {
  readonly type = IDEAS_TRACK_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqTrackSuccessAction implements Action {
  readonly type = IDEAS_TRACK_SUCCESS;
  constructor(public payload: string) {}
}

// UNTRACK
export class IdeasReqUntrackAction implements Action {
  readonly type = IDEAS_UNTRACK;
  constructor(public payload: string) {}
}
export class IdeasReqUntrackErrorAction implements Action {
  readonly type = IDEAS_UNTRACK_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqUntrackSuccessAction implements Action {
  readonly type = IDEAS_UNTRACK_SUCCESS;
  constructor(public payload: string) {}
}

// LINK
export class IdeasReqLinkItemAction implements Action {
  readonly type = IDEAS_LINK_ITEM;
  constructor(public payload: IdeadLinkItemRequest) {}
}
export class IdeasReqLinkItemErrorAction implements Action {
  readonly type = IDEAS_LINK_ITEM_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqLinkItemSuccessAction implements Action {
  readonly type = IDEAS_LINK_ITEM_SUCCESS;
  constructor(public payload?: void) {}
}

// UNLINK
export class IdeasReqUnLinkItemAction implements Action {
  readonly type = IDEAS_UNLINK_ITEM;
  constructor(public payload: IdeadLinkItemRequest) {}
}
export class IdeasReqUnLinkItemErrorAction implements Action {
  readonly type = IDEAS_UNLINK_ITEM_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqUnLinkItemSuccessAction implements Action {
  readonly type = IDEAS_UNLINK_ITEM_SUCCESS;
  constructor(public payload?: void) {}
}

// SAVE IMPORTANCE
export class IdeasReqSaveImportanceAction implements Action {
  readonly type = IDEAS_SAVE_IMPORTANCE;
  constructor(public payload: IdeaChangeImportanceRequest) {}
}
export class IdeasReqSaveImportanceErrorAction implements Action {
  readonly type = IDEAS_SAVE_IMPORTANCE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqSaveImportanceSuccessAction implements Action {
  readonly type = IDEAS_SAVE_IMPORTANCE_SUCCESS;
  constructor(public payload: IdeaChangeImportanceRequest) {}
}

// SAVE WORKFLOW STATUS
export class IdeasReqSaveWorkflowStatusAction implements Action {
  readonly type = IDEAS_SAVE_WORKFLOW_STATUS;
  constructor(public payload: IdeaChangeWorkflowStatusRequest) {}
}
export class IdeasReqSaveWorkflowStatusErrorAction implements Action {
  readonly type = IDEAS_SAVE_WORKFLOW_STATUS_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqSaveWorkflowStatusSuccessAction implements Action {
  readonly type = IDEAS_SAVE_WORKFLOW_STATUS_SUCCESS;
  constructor(public payload: IdeaChangeWorkflowStatusRequest) {}
}

// SAVE INNER STATUS
export class IdeasReqSaveInnerStatusAction implements Action {
  readonly type = IDEAS_SAVE_INNER_STATUS;
  constructor(public payload: IdeadChangeInnerStatusRequest) {}
}
export class IdeasReqSaveInnerStatusErrorAction implements Action {
  readonly type = IDEAS_SAVE_INNER_STATUS_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqSaveInnerStatusSuccessAction implements Action {
  readonly type = IDEAS_SAVE_INNER_STATUS_SUCCESS;
  constructor(public payload: IdeadChangeInnerStatusRequest) {}
}

// PARTIAL UPDATE
export class IdeasReqPartialUpdateAction implements Action {
  readonly type = IDEAS_PARTIAL_UPDATE;
  constructor(public payload: PickRequired<DBIdea, 'id'>) {}
}
export class IdeasReqPartialUpdateErrorAction implements Action {
  readonly type = IDEAS_PARTIAL_UPDATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqPartialUpdateSuccessAction implements Action {
  readonly type = IDEAS_PARTIAL_UPDATE_SUCCESS;
  constructor(public payload: PickRequired<DBIdea, 'id'>) {}
}

// ATTACH FILE
export class IdeasReqAttachFileAction implements Action {
  readonly type = IDEAS_ATTACH_FILE;
  constructor(public payload: IdeadAddAttachmentRequest) {}
}
export class IdeasReqAttachFileErrorAction implements Action {
  readonly type = IDEAS_ATTACH_FILE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqAttachFileSuccessAction implements Action {
  readonly type = IDEAS_ATTACH_FILE_SUCCESS;
  constructor(public payload: IdeadAddAttachmentRequest) {}
}

// DETACH FILE
export class IdeasReqDetachFileAction implements Action {
  readonly type = IDEAS_DETACH_FILE;
  constructor(public payload: IdeadDelAttachmentRequest) {}
}
export class IdeasReqDetachFileErrorAction implements Action {
  readonly type = IDEAS_DETACH_FILE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class IdeasReqDetachFileSuccessAction implements Action {
  readonly type = IDEAS_DETACH_FILE_SUCCESS;
  constructor(public payload: IdeadDelAttachmentRequest) {}
}

export type IdeaAction =
  | IdeasSetListAction
  | IdeasSetPageAction
  | IdeasSetStatusAction
  | IdeasUpdateOneAction
  | IdeasSetSelectedByIdAction
  | IdeasUpdateLinkedItemsCountAction
  //
  | IdeasReqAddAction
  | IdeasReqAddErrorAction
  | IdeasReqAddSuccessAction
  //
  | IdeasReqSaveAction
  | IdeasReqSaveErrorAction
  | IdeasReqSaveSuccessAction
  //
  | IdeasReqDelAction
  | IdeasReqDelErrorAction
  | IdeasReqDelSuccessAction
  //
  | IdeasReqArchiveAction
  | IdeasReqArchiveErrorAction
  | IdeasReqArchiveSuccessAction
  //
  | IdeasReqUnArchiveAction
  | IdeasReqUnArchiveErrorAction
  | IdeasReqUnArchiveSuccessAction
  //
  | IdeasReqAddTagAction
  | IdeasReqAddTagErrorAction
  | IdeasReqAddTagSuccessAction
  //
  | IdeasReqDelTagAction
  | IdeasReqDelTagErrorAction
  | IdeasReqDelTagSuccessAction
  //
  | IdeasReqVoteAction
  | IdeasReqVoteErrorAction
  | IdeasReqVoteSuccessAction
  //
  | IdeasReqDelVoteAction
  | IdeasReqDelVoteErrorAction
  | IdeasReqDelVoteSuccessAction
  //
  | IdeasReqTrackAction
  | IdeasReqTrackErrorAction
  | IdeasReqTrackSuccessAction
  //
  | IdeasReqUntrackAction
  | IdeasReqUntrackErrorAction
  | IdeasReqUntrackSuccessAction
  //
  | IdeasReqLinkItemAction
  | IdeasReqLinkItemErrorAction
  | IdeasReqLinkItemSuccessAction
  //
  | IdeasReqUnLinkItemAction
  | IdeasReqUnLinkItemErrorAction
  | IdeasReqUnLinkItemSuccessAction
  //
  | IdeasReqSaveImportanceAction
  | IdeasReqSaveImportanceErrorAction
  | IdeasReqSaveImportanceSuccessAction
  //
  | IdeasReqSaveWorkflowStatusAction
  | IdeasReqSaveWorkflowStatusErrorAction
  | IdeasReqSaveWorkflowStatusSuccessAction
  //
  | IdeasReqSaveInnerStatusAction
  | IdeasReqSaveInnerStatusErrorAction
  | IdeasReqSaveInnerStatusSuccessAction
  //
  | IdeasReqAttachFileAction
  | IdeasReqAttachFileErrorAction
  | IdeasReqAttachFileSuccessAction
  //
  | IdeasReqDetachFileAction
  | IdeasReqDetachFileErrorAction
  | IdeasReqDetachFileSuccessAction
  //
  | IdeasReqPartialUpdateAction
  | IdeasReqPartialUpdateErrorAction
  | IdeasReqPartialUpdateSuccessAction;
