import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { PORTAL_GET_SUCCESS, PortalReqGetSuccessAction } from 'src/app/core/store/actions/portal';

export { PORTAL_GET_SUCCESS as PORTAL_REQ_GET_SUCCESS };

export const CUSTOM_FIELDS_ADD = '[CustomFields] add';
export const CUSTOM_FIELDS_ADD_ERROR = '[CustomFields] add error';
export const CUSTOM_FIELDS_ADD_SUCCESS = '[CustomFields] add success';

export const CUSTOM_FIELDS_UPDATE = '[CustomFields] update';
export const CUSTOM_FIELDS_UPDATE_ERROR = '[CustomFields] update error';
export const CUSTOM_FIELDS_UPDATE_SUCCESS = '[CustomFields] update success';

export const CUSTOM_FIELDS_DELETE = '[CustomFields] delete';
export const CUSTOM_FIELDS_DELETE_ERROR = '[CustomFields] delete error';
export const CUSTOM_FIELDS_DELETE_SUCCESS = '[CustomFields] delete success';

// ADD
export class CustomFieldsReqAddAction implements Action {
  readonly type = CUSTOM_FIELDS_ADD;
  constructor(public payload: Partial<CustomField>) {}
}
export class CustomFieldsReqAddErrorAction implements Action {
  readonly type = CUSTOM_FIELDS_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CustomFieldsReqAddSuccessAction implements Action {
  readonly type = CUSTOM_FIELDS_ADD_SUCCESS;
  constructor(public payload: CustomField) {}
}

// UPDATE
export class CustomFieldsUpdateAction implements Action {
  readonly type = CUSTOM_FIELDS_UPDATE;
  constructor(public payload: CustomField) {}
}
export class CustomFieldsUpdateErrorAction implements Action {
  readonly type = CUSTOM_FIELDS_UPDATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CustomFieldsUpdateSuccessAction implements Action {
  readonly type = CUSTOM_FIELDS_UPDATE_SUCCESS;
  constructor(public payload: CustomField) {}
}

// DELETE
export class CustomFieldsDeleteAction implements Action {
  readonly type = CUSTOM_FIELDS_DELETE;
  constructor(public payload: string) {}
}
export class CustomFieldsDeleteErrorAction implements Action {
  readonly type = CUSTOM_FIELDS_DELETE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CustomFieldsDeleteSuccessAction implements Action {
  readonly type = CUSTOM_FIELDS_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export type CustomFieldsAction =
  | PortalReqGetSuccessAction
  //
  | CustomFieldsReqAddAction
  | CustomFieldsReqAddErrorAction
  | CustomFieldsReqAddSuccessAction
  //
  | CustomFieldsUpdateAction
  | CustomFieldsUpdateErrorAction
  | CustomFieldsUpdateSuccessAction
  //
  | CustomFieldsDeleteAction
  | CustomFieldsDeleteErrorAction
  | CustomFieldsDeleteSuccessAction;
