<form novalidate="" [formGroup]="form" (ngSubmit)="done()" class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">
    {{ 'common.text29' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content" [ngSwitch]="category.ideasCnt > 0">
    <div *ngSwitchCase="false" class="dialog-desc">
      {{ 'common.text35' | craftTerm }}
    </div>
    <craft-delete-categories-option-select
      *ngSwitchCase="true"
      [form]="form"
      [deletedCategory]="category"
    ></craft-delete-categories-option-select>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button
        class="btn btn-cancel dialog-btn"
        type="button"
        (click)="close()"
        [disabled]="isRequestSending"
        mat-button=""
        craftDisableFocus
      >
        Cancel
      </button>
      <div
        matTooltip="Choose one of the two options above"
        [matTooltipDisabled]="form.invalid"
        matTooltipPosition="above"
      >
        <button
          class="btn btn-blue dialog-btn"
          type="submit"
          [disabled]="form.invalid || isRequestSending"
          mat-button=""
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</form>
