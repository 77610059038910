import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { isObject } from 'lodash';

export type BodyComponentRef<C> = {
  ref: ComponentRef<C>;
  destroy: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public appendComponentToBody<C>(component: Type<C>, componentProps?: any): BodyComponentRef<C> {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    if (isObject(componentProps) && isObject(componentRef.instance)) {
      Object.assign(componentRef.instance, componentProps);
    }

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);
    componentRef.changeDetectorRef.detectChanges();

    return {
      ref: componentRef,
      destroy: () => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      },
    };
  }
}
