<div class="calendar">
  <div class="dt">
    <mat-datepicker-toggle [for]="datepicker" [disableRipple]="true">
      <craft-icon name="general--calendar-md" matDatepickerToggleIcon></craft-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker (opened)="onOpen()"></mat-datepicker>
  </div>
  <div
    class="dt-input"
    [ngClass]="{ opened: datepicker.opened }"
    (click)="datepicker.opened ? datepicker.close() : datepicker.open()"
  >
    <input
      readonly
      [matDatepicker]="datepicker"
      [placeholder]="placeholder"
      [value]="value"
      (dateChange)="sendValue($event.value)"
    />
  </div>
</div>

<div style="display: none">
  <div class="dt-footer" #datepickerFooter>
    <button mat-button (click)="clear()" class="clear">Clear</button>
    <button mat-button (click)="done()" class="done">Apply</button>
  </div>
</div>
