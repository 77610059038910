import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { COMPANY_DELETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { DeleteOption } from 'src/app/shared/dialogs/delete-company-dialog/delete-companies-option-select/delete-companies-option-select.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  selector: 'craft-delete-company-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.scss'],
})
export class DeleteCompanyDialogComponent extends Destroyer implements OnInit {
  public isRequestSending = false;
  public DeleteOption = DeleteOption;

  public form = new FormGroup(
    {
      option: new FormControl<DeleteOption | null>(null, Validators.required),
      moveToCompany: new FormControl<string | null>(null),
    },
    (form: AbstractControl) => {
      const { option, moveToCompany } = form.value;
      return option === DeleteOption.moveTo && !moveToCompany ? { companyRequired: true } : null;
    },
  );

  constructor(
    private websocketService: WebSocketService,
    private companyStore: CompaniesStoreService,
    @Inject(MAT_DIALOG_DATA) public deletedCompanyIds: string[],
    private dialogRef: MatDialogRef<DeleteCompanyDialogComponent, DialogResult>,
  ) {
    super();
  }

  public ngOnInit() {
    this.websocketService.message$
      .pipe(
        filter((msg) => {
          return (
            msg.event === COMPANY_DELETE_EVENT && //
            msg.data?.companyId &&
            this.deletedCompanyIds.includes(msg.data.companyId)
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.dialogRef.close({ done: true });
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    if (!this.form.valid) return;

    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    const val = this.form.value;
    this.companyStore
      .delete({
        companyIds: this.deletedCompanyIds!,
        moveToCompanyId: val.option === DeleteOption.moveTo ? val.moveToCompany : void 0,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
