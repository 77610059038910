import { ErrorHandler } from '@angular/core';
import * as atatus from 'atatus-spa';
atatus.config('3342fc4878284184b3604d9fe1c828b7').install();

class CraftErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error);
    atatus?.notify(error?.originalError || error);
  }
}

export default CraftErrorHandler;
