import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyUser } from 'src/app/core/models/users-models';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './delete-company-user-dialog.component.html',
  styleUrls: ['./delete-company-user-dialog.component.scss'],
})
export class DeleteCompanyUserDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public user: CompanyUser, //
    private dialogRef: MatDialogRef<DeleteCompanyUserDialogComponent, DialogResult>,
  ) {}

  public close() {
    this.dialogRef.close({ done: false });
  }

  public done() {
    this.dialogRef.close({ done: true });
  }
}
