import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompaniesListRequest, CompaniesListResponse, Company, CompanyDelRequest, DBCompany } from 'src/app/core/models/companies.models';
import { CompaniesBackendService } from 'src/app/core/services/backend/companies-backend.service';
import {
  getCompaniesActiveListSelector,
  getCompaniesIdsSelector,
  getCompaniesListSelector,
  getCompaniesSavingIdSelector,
  getCompaniesSelector,
  getSelectedCompanySelector,
} from 'src/app/core/store/reducers/companies';
import {
  CompaniesReqAddAction,
  CompaniesReqAddErrorAction,
  CompaniesReqAddSuccessAction,
  CompaniesReqDelAction,
  CompaniesReqDelErrorAction,
  CompaniesReqDelSuccessAction,
  CompaniesReqListAction,
  CompaniesReqListErrorAction,
  CompaniesReqListSuccessAction,
  CompaniesReqOneAction,
  CompaniesReqOneErrorAction,
  CompaniesReqOneSuccessAction,
  CompaniesReqSaveAction,
  CompaniesReqSaveErrorAction,
  CompaniesReqSaveSuccessAction,
  CompaniesSelectAction,
} from '../actions/companies';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class CompaniesStoreService {
  public readonly map$ = this.store$.pipe(select(getCompaniesSelector));
  public readonly ids$ = this.store$.pipe(select(getCompaniesIdsSelector));
  public readonly list$ = this.store$.pipe(select(getCompaniesListSelector));
  public readonly selected$ = this.store$.pipe(select(getSelectedCompanySelector));
  public readonly savingId$ = this.store$.pipe(select(getCompaniesSavingIdSelector));
  public readonly activeList$ = this.store$.pipe(select(getCompaniesActiveListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private cbs: CompaniesBackendService,
  ) {}

  public selectById(id: string | undefined): void {
    this.store$.dispatch(new CompaniesSelectAction(id));
  }

  public loadListAll(): Observable<CompaniesListResponse> {
    return this.loadList({ withArchival: true });
  }

  public loadListWithoutArchival(): Observable<CompaniesListResponse> {
    return this.loadList({ withManagementFilter: true });
  }

  public loadList(req: CompaniesListRequest): Observable<CompaniesListResponse> {
    this.store$.dispatch(new CompaniesReqListAction(req));

    return this.cbs.getList(req).pipe(
      map((res) => {
        this.store$.dispatch(new CompaniesReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompaniesReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public loadOne(id: string): Observable<DBCompany> {
    this.store$.dispatch(new CompaniesReqOneAction(id));

    return this.cbs.getDetails(id).pipe(
      map((company) => {
        this.store$.dispatch(new CompaniesReqOneSuccessAction(company));
        return company;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompaniesReqOneErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(company: Company): Observable<DBCompany> {
    this.store$.dispatch(new CompaniesReqAddAction(company));

    return this.cbs.add(company).pipe(
      map((res) => {
        this.store$.dispatch(new CompaniesReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompaniesReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(company: DBCompany): Observable<DBCompany> {
    this.store$.dispatch(new CompaniesReqSaveAction(company));

    return this.cbs.update(company).pipe(
      map((res) => {
        this.store$.dispatch(new CompaniesReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompaniesReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(req: CompanyDelRequest): Observable<void> {
    this.store$.dispatch(new CompaniesReqDelAction(req));

    return this.cbs.delete(req).pipe(
      map(() => {
        this.store$.dispatch(new CompaniesReqDelSuccessAction(req));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompaniesReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
