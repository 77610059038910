import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DBCategory } from 'src/app/core/models/categories.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { BaseSingleSelectComponent } from 'src/app/shared/components/craft-single-select/craft-single-select.component';

export enum DeleteOption {
  moveTo = 1,
  deleteAll = 2,
}

export type DeleteCategoryForm = {
  option: FormControl<DeleteOption | null>;
  moveToCategoryId: FormControl<string | null>;
};

@Component({
  selector: 'craft-delete-categories-option-select',
  templateUrl: './delete-categories-option-select.component.html',
  styleUrls: ['./delete-categories-option-select.component.scss'],
})
export class DeleteCategoriesOptionSelectComponent extends BaseSingleSelectComponent implements OnInit {
  @Input() public deletedCategory: DBCategory;
  @Input() public form: FormGroup<DeleteCategoryForm>;

  public DeleteOption = DeleteOption;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private categoriesStore: CategoriesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.categoriesStore.list$.pipe(takeUntil(this.destroy$)).subscribe((allCategories) => {
      const categories = allCategories.filter((c) => this.deletedCategory.id !== c.id);
      this.setItems(categories, void 0);
    });
  }

  public selectItem(category: DBCategory) {
    super.selectItem(category);
    this.form.patchValue({ option: DeleteOption.moveTo, moveToCategoryId: category.id });
  }
}
