import { Action } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { TerminologyItem } from 'src/app/core/models/terminology.models';

export const TERMS_LOAD = '[Terms] load';
export const TERMS_LOAD_ERROR = '[Terms] load error';
export const TERMS_LOAD_SUCCESS = '[Terms] load success';

export const TERMS_UPDATE = '[Terms] update';
export const TERMS_UPDATE_ERROR = '[Terms] update error';
export const TERMS_UPDATE_SUCCESS = '[Terms] update success';

// LIST
export class TermsLoadAction implements Action {
  readonly type = TERMS_LOAD;
  constructor(public payload?: string) {}
}
export class TermsLoadErrorAction implements Action {
  readonly type = TERMS_LOAD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TermsLoadSuccessAction implements Action {
  readonly type = TERMS_LOAD_SUCCESS;
  constructor(public payload: readonly TerminologyItem[]) {}
}

// UPDATE
export class TermsUpdateAction implements Action {
  readonly type = TERMS_UPDATE;
  constructor(public payload: TerminologyItem) {}
}
export class TermsUpdateErrorAction implements Action {
  readonly type = TERMS_UPDATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TermsUpdateSuccessAction implements Action {
  readonly type = TERMS_UPDATE_SUCCESS;
  constructor(public payload: Dictionary<Partial<TerminologyItem>>) {}
}

export type TermsAction =
  | TermsLoadAction
  | TermsLoadErrorAction
  | TermsLoadSuccessAction
  //
  | TermsUpdateAction
  | TermsUpdateErrorAction
  | TermsUpdateSuccessAction;
