import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import {
  CUSTOM_FIELDS_ADD_SUCCESS,
  CUSTOM_FIELDS_DELETE,
  CUSTOM_FIELDS_UPDATE,
  CUSTOM_FIELDS_UPDATE_SUCCESS,
  CustomFieldsAction,
  PORTAL_REQ_GET_SUCCESS,
} from 'src/app/core/store/actions/custom-fields';

export type CustomFieldsState = {
  readonly ids: string[];
  readonly customFields: Dictionary<CustomField>;
};

export const initialState: CustomFieldsState = {
  ids: [],
  customFields: {},
};

export function reducer(state = initialState, action: CustomFieldsAction): CustomFieldsState {
  switch (action.type) {
    case PORTAL_REQ_GET_SUCCESS: {
      const list = action.payload.portal.custom || [];
      return {
        ...state,
        ids: list.map((cf) => cf.id),
        customFields: list.reduce<Dictionary<CustomField>>((acc, cf) => {
          acc[cf.id] = cf;
          return acc;
        }, {}),
      };
    }

    case CUSTOM_FIELDS_ADD_SUCCESS: {
      const field = action.payload;

      const customFields: Dictionary<CustomField> = {
        ...state.customFields,
        [field.id]: field,
      };

      return {
        ...state,
        customFields,
        ids: [...state.ids, field.id],
      };
    }

    case CUSTOM_FIELDS_UPDATE:
    case CUSTOM_FIELDS_UPDATE_SUCCESS: {
      const field = action.payload;

      const customFields: Dictionary<CustomField> = {
        ...state.customFields,
        [field.id]: field,
      };

      return {
        ...state,
        customFields,
      };
    }

    case CUSTOM_FIELDS_DELETE: {
      const id = action.payload;

      const customFields = { ...state.customFields };
      delete customFields[id];

      return {
        ...state,
        customFields,
        ids: state.ids.filter((_id) => _id !== id),
      };
    }

    default:
      return state;
  }
}

const getCustomFieldsIds = (state: CustomFieldsState) => state.ids;
const getCustomFieldsMap = (state: CustomFieldsState) => state.customFields;

export const getCustomFieldsStateSelector = createFeatureSelector<CustomFieldsState>('customFieldsState');
export const getCustomFieldsIdsSelector = createSelector(getCustomFieldsStateSelector, getCustomFieldsIds);
export const getCustomFieldsMapSelector = createSelector(getCustomFieldsStateSelector, getCustomFieldsMap);

export const getCustomFieldsListSelector = createSelector(
  getCustomFieldsIdsSelector,
  getCustomFieldsMapSelector,
  (ids, map) => {
    return ids.map((id) => map[id]);
  },
);

export const getCustomFieldsEnabledListSelector = createSelector(getCustomFieldsListSelector, (list) => {
  return list.filter((cf) => cf.isEnabled);
});
