import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { IdeasVisibility } from 'src/app/core/enums/IdeaVisibility';
import { Category } from 'src/app/core/models/categories.models';
import { Portal } from 'src/app/core/models/portal.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { CategoryPrivacySettingsComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-settings.component';

@Component({
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
})
export class CategoryCreateComponent extends Destroyer implements AfterViewInit, OnInit {
  public isSaving = false;
  public isSending = false;
  public isSubmitted = false;

  @ViewChild('nameInput') public nameInputRef: ElementRef;

  public readonly form = new FormGroup({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    description: new FormControl<string>('', { nonNullable: true }),
    companies: new FormControl<readonly string[]>([], { nonNullable: true }),
    isPrivate: new FormControl<boolean>(false, { nonNullable: true }),
    isDefault: new FormControl<boolean>(false, { nonNullable: true }),
    ideaVisibility: new FormControl<IdeasVisibility>(IdeasVisibility.visibleToAllUsers, { nonNullable: true }),
  });

  constructor(
    private dialog: MatDialog,
    private entityFactory: EntityFactory,
    private portalStore: PortalStoreService,
    private categoriesStore: CategoriesStoreService,
    private dialogRef: MatDialogRef<CategoryCreateComponent, Category>,
  ) {
    super();
  }

  public ngOnInit() {
    const portal = this.portalStore.portalSnapshot as Portal;

    if (portal.categoryDescriptionMandatory) {
      this.form.controls['description'].setValidators(Validators.required);
    }
  }

  public ngAfterViewInit() {
    this.setNameFocus();
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSaving = true;
    this.entityFactory
      .createCategory(this.form.value)
      .pipe(
        switchMap((category) => {
          return this.categoriesStore.add(category);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.isSaving = true;
        this.dialogRef.close(category);
      });
  }

  public showPrivacyDialog() {
    this.entityFactory
      .createCategory(this.form.value)
      .pipe(
        switchMap((category) => this.openPrivacyDialog(category)),
        filter((category): category is Category => !!category),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.form.patchValue(category);
      });
  }

  private openPrivacyDialog(category: Category): Observable<Category | undefined> {
    return this.dialog
      .open(CategoryPrivacySettingsComponent, {
        backdropClass: 'night-backdrop',
        data: category,
      })
      .afterClosed();
  }

  private setNameFocus() {
    if (this.nameInputRef) {
      this.nameInputRef.nativeElement.focus();
    }
  }
}
