import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary, merge } from 'lodash';
import { TERMINOLOGY_CRAFT_ITEMS, TERMINOLOGY_DEFAULT } from 'src/app/core/dictionaries/terminology';
import { TerminologyItem } from 'src/app/core/models/terminology.models';
import { TERMS_LOAD_SUCCESS, TERMS_UPDATE, TermsAction } from 'src/app/core/store/actions/terms';
import { keyByWithValue } from 'src/app/core/utils/app-utils';

export type TerminologyState = {
  readonly ids: readonly string[];
  readonly terms: Dictionary<TerminologyItem>;
};

export const initialState: TerminologyState = {
  ids: TERMINOLOGY_DEFAULT.map((t) => t.single),
  terms: keyByWithValue(
    [...TERMINOLOGY_CRAFT_ITEMS, ...TERMINOLOGY_DEFAULT],
    (t) => t,
    (t) => t.single,
  ),
};

export function reducer(state = initialState, action: TermsAction): TerminologyState {
  switch (action.type) {
    case TERMS_LOAD_SUCCESS:
      const items = action.payload;
      const ids = Array.from(new Set(items.map((t) => t.single).concat(state.ids)));
      const terms = keyByWithValue(
        items,
        (t) => t,
        (t) => t.single,
      );
      return {
        ...state,
        ids,
        terms: merge({}, state.terms, terms),
      };

    case TERMS_UPDATE: {
      const term = action.payload;

      return {
        ...state,
        terms: {
          ...state.terms,
          [term.single]: term,
        },
      };
    }

    default:
      return state;
  }
}

const getIds = (state: TerminologyState) => state.ids;
const getTermsStateSelector = createFeatureSelector<TerminologyState>('termsState');
const getTermsIdsSelector = createSelector(getTermsStateSelector, getIds);

export const getTermsListSelector = createSelector(getTermsStateSelector, getTermsIdsSelector, (state, ids) => {
  return ids.map((id) => state.terms[id]);
});

export const getTermsListPortalOnlySelector = createSelector(getTermsListSelector, (list) => {
  return list.filter((t) => t.isPortalOnly);
});

export const getTerminologyWordsSelector = createSelector(
  getTermsStateSelector,
  getTermsIdsSelector,
  (state, ids): Dictionary<string> => {
    return ids.reduce<Dictionary<string>>((acc, id) => {
      const item = state.terms[id];
      return {
        ...acc,
        ...getCaseForms(item.single, item.translateSingle),
        ...getCaseForms(item.plural, item.translatePlural),
      };
    }, {});
  },
);

function getCaseForms(label = '', word = ''): Dictionary<string> {
  const lLower = label.toLocaleLowerCase();
  const lUpper = label.toLocaleUpperCase();
  const lFirst = lUpper.charAt(0) + lLower.substring(1);

  let wLower = word.toLocaleLowerCase();
  let wUpper = word.toLocaleUpperCase();
  let wFirst = wUpper.charAt(0) + wLower.substring(1);

  return {
    [lLower]: wLower,
    [lFirst]: wFirst,
    [lUpper]: wUpper,
  };
}
