import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { TerminologyStoreService } from 'src/app/core/store/services/terminology-store.service';
import { CraftPreloaderService } from 'src/app/shared/components/craft-preloader/craft-preloader.service';
import { PortalStoreService } from '../store/services/portal-store.service';

@Injectable({
  providedIn: 'root',
})
export class PortalLoadGuard {
  constructor(
    private portalStore: PortalStoreService,
    private termsStore: TerminologyStoreService,
    private preloaderService: CraftPreloaderService,
  ) {}

  public canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean> | boolean {
    this.preloaderService.show();
    return this.portalStore.portal$.pipe(
      take(1),
      switchMap((portal) => {
        if (portal) return of(true);
        return forkJoin([
          this.portalStore.load(), //
          this.termsStore.loadPortalTerms(),
        ]).pipe(map(() => true));
      }),
      finalize(() => {
        this.preloaderService.hide();
      }),
    );
  }
}
