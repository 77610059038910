import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { catchError, Observable, switchMap, take, throwError } from 'rxjs';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { TerminologyItem } from 'src/app/core/models/terminology.models';
import { TerminologyBackendService } from 'src/app/core/services/backend/terminology-backend.service';
import {
  TermsLoadAction,
  TermsLoadErrorAction,
  TermsLoadSuccessAction,
  TermsUpdateAction,
} from 'src/app/core/store/actions/terms';
import { CoreState } from 'src/app/core/store/reducers';
import { getTerminologyWordsSelector, getTermsListPortalOnlySelector } from 'src/app/core/store/reducers/terms';

@Injectable({
  providedIn: 'root',
})
export class TerminologyStoreService {
  public readonly words$ = this.store$.pipe(select(getTerminologyWordsSelector));
  public readonly list$ = this.store$.pipe(select(getTermsListPortalOnlySelector));

  constructor(
    private store$: Store<CoreState>, //
    private bs: TerminologyBackendService,
  ) {}

  public loadPortalTerms(): Observable<Dictionary<string>> {
    this.store$.dispatch(new TermsLoadAction());

    return this.bs.getPortalTerms().pipe(
      switchMap((res) => {
        this.store$.dispatch(new TermsLoadSuccessAction(res));
        return this.words$;
      }),
      take(1),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TermsLoadErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public loadWorkspaceTerms(workspaceId: string): Observable<Dictionary<string>> {
    this.store$.dispatch(new TermsLoadAction(workspaceId));

    return this.bs.getProductTerms(workspaceId).pipe(
      switchMap((res) => {
        this.store$.dispatch(new TermsLoadSuccessAction(res));
        return this.words$;
      }),
      take(1),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TermsLoadErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public updatePortalTerm(term: TerminologyItem): Observable<void> {
    this.store$.dispatch(new TermsUpdateAction(term));

    return this.bs.updatePortalTerm(term).pipe(
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TermsLoadErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
