<button
  type="button"
  class="btn dialog-btn ellipsis"
  mat-button=""
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="multiSelectMenu"
>
  <ng-container *ngIf="isPrivateControl.value === false; then allUsersTmpl; else companiesTmpl"></ng-container>
  <craft-icon class="svg-icon text-size-15" name="arrows--arrow-head-down-md"></craft-icon>
</button>

<mat-menu #multiSelectMenu="matMenu">
  <ng-template matMenuContent="">
    <mat-radio-group [formControl]="isPrivateControl" class="d-contents">
      <mat-radio-button [value]="false" class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue">
        <ng-container *ngTemplateOutlet="allUsersTmpl"></ng-container>
      </mat-radio-button>

      <mat-radio-button
        *ngIf="companiesSize > 0"
        [value]="true"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="companiesSubMenu"
        class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue"
      >
        <div class="d-flex align-items-center gap-10 ellipsis" (click)="$event.stopPropagation()">
          <ng-container *ngTemplateOutlet="companiesTmpl"></ng-container>
          <craft-icon class="svg-icon text-size-15 shrink-0" name="arrows--arrow-head-right-md"></craft-icon>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </ng-template>
</mat-menu>

<mat-menu #companiesSubMenu="matMenu" class="companies-sub-menu">
  <ng-template matMenuContent="">
    <div *ngIf="hasSearch" class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input
        type="text"
        #searchInput
        (click)="$event.stopPropagation()"
        [placeholder]="searchPlaceholder"
        [formControl]="searchControl"
      />
    </div>

    <div class="menu-scroll">
      <div
        mat-menu-item=""
        class="item-checkbox"
        *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
        (click)="$event.stopPropagation()"
        craftDisableFocus=""
      >
        <mat-checkbox
          [checked]="selectedSet.has(item[idFieldName])"
          (change)="selectItem(item)"
          class="checkbox-black small"
        >
          {{ item[labelFieldName] }}
        </mat-checkbox>
      </div>
    </div>

    <div class="dropdown-footer">
      <button (click)="clearSelected($event)" type="button" class="btn">Clear</button>
      <button (click)="applySelected()" type="button" class="btn btn-blue">Apply</button>
    </div>
  </ng-template>
</mat-menu>

<ng-template #allUsersTmpl>
  <craft-icon class="svg-icon text-size-15" name="subnav--team-md"></craft-icon>
  <span class="mr-8">All portal users (default)</span>
</ng-template>

<ng-template #companiesTmpl>
  <craft-icon class="svg-icon text-size-15" name="custom--lock-md"></craft-icon>
  <span class="mr-8 ellipsis"
    >{{ selectedIds?.length | i18nPlural: selectedCountMapping | craftTerm: { count: selectedIds?.length } }}
  </span>
</ng-template>
