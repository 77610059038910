import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CompaniesUsersListRequest,
  CompanyUserStatus,
  MoveToCompanyRequest,
} from 'src/app/core/models/companies.models';
import { CompanyUser } from 'src/app/core/models/users-models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { getFullName } from 'src/app/core/utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class CompanyUsersBackendService {
  constructor(private bs: BackendService) {}

  public getUsersList(req: CompaniesUsersListRequest): Observable<CompanyUser[]> {
    return this.bs.get(`company/users/${req.companyId}`, { skip: req.skip, limit: req.limit }).pipe(
      map((data) => {
        return this.mapUsersList(data);
      }),
    );
  }

  public getRegisteredUsersList(): Observable<CompanyUser[]> {
    return this.bs.get(`company/allUsers/`).pipe(
      map((data) => {
        return this.mapUsersList(data);
      }),
    );
  }

  public getCompanyUsersList(): Observable<CompanyUser[]> {
    return this.bs.get(`company/allCompanyUsers/`).pipe(
      map((data) => {
        return this.mapUsersList(data);
      }),
    );
  }

  public addUser(user: CompanyUser): Observable<CompanyUser> {
    return this.bs
      .post(`company/invite`, {
        companyId: user.companyId,
        toEmail: user.email,
      })
      .pipe(
        map((res) => {
          return this.mapUser(res);
        }),
      );
  }

  public deleteUser(user: CompanyUser): Observable<void> {
    return this.bs
      .delete(`company/invite`, {
        companyId: user.companyId,
        toEmail: user.email ? window.encodeURIComponent(user.email) : void 0,
      })
      .pipe(map(() => {}));
  }

  public moveToCompany(req: MoveToCompanyRequest): Observable<void> {
    return this.bs.put(`company/moveUser`, req).pipe(map(() => {}));
  }

  public resendInvite(user: CompanyUser): Observable<void> {
    return this.bs
      .post(`company/resend`, {
        companyId: user.companyId,
        toEmail: user.email,
      })
      .pipe(map(() => {}));
  }

  private mapUser(user: any): CompanyUser {
    const status = Number.parseInt(user.status, 0) || CompanyUserStatus.undefined;

    return {
      ...user,
      status,
      id: status === CompanyUserStatus.invited ? null : user.id,
      color: status === CompanyUserStatus.invited ? null : '#f59d00',
      invitedDt: user.invitedDt ? new Date(user.invitedDt) : void 0,
      signUpDt: user.signUpDt ? new Date(user.signUpDt) : void 0,
      fullName: getFullName(user),
      feedbacksCount: Number.parseInt(user.feedbacksCount, 0) || 0,
      userType: 'company',
    };
  }

  private mapUsersList(data: any) {
    if (!data || !Array.isArray(data.list)) return [];
    return (data.list as CompanyUser[]).map((u) => this.mapUser(u));
  }
}
