export const environment = {
  apiRoot: `api`,
  captchaSiteKey: '6Ler8xQUAAAAACFpSyBQZ85mh501RBaIPFjXuEiK',
  clientIdHeader: 'X-CLIENT-ID',
  intercomId: 'cabzeyor',
  googleTagId: 'GTM-NLJH4LZ',
  segmentKey: '1rJxM5rz5WEhLbhVZa9N1ynkQrbNy3Ll',
  preProduction: false,
  production: true,
  socketUrl: 'ws/ch@userid',
  spriteUrl: `https://d30vpxmhzvh8ht.cloudfront.net/FP/sprite.svg`,
  msTeamsUrl: 'https://teams.microsoft.com/l/app/4ef470d8-e4c6-41fc-a5aa-c532730dd635?source=app-details-dialog',
  type: 'prod',
  googleSSOClientId: '215662090526-0o72a34rik5j6vn0donn117qp2cnt3rb.apps.googleusercontent.com',
};
