import { Injectable } from '@angular/core';
import { omit, pick } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TERMINOLOGY_CRAFT_ITEMS, TERMINOLOGY_DEFAULT } from 'src/app/core/dictionaries/terminology';
import { TerminologyItem } from 'src/app/core/models/terminology.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class TerminologyBackendService {
  constructor(private bs: BackendService) {}

  public updatePortalTerm(term: TerminologyItem): Observable<void> {
    const serverReq = pick(term, ['single', 'translateSingle', 'translatePlural']);
    return this.bs.put('iportal/updateTerminology', serverReq).pipe(map(() => void 0));
  }

  public getProductTerms(productId: string): Observable<readonly TerminologyItem[]> {
    return this.bs.get(`iportal/terminology`, { productId }).pipe(
      map((res) => {
        const serverTerms: TerminologyItem[] = (res?.terminology || []).map((term: TerminologyItem) => ({
          ...omit(term, 'id'),
          isPortalOnly: false,
        }));
        return [...TERMINOLOGY_CRAFT_ITEMS, ...serverTerms];
      }),
    );
  }

  public getPortalTerms(): Observable<readonly TerminologyItem[]> {
    return this.bs.get('iportal/portalTerminology').pipe(
      map((res) => {
        const serverTerms: TerminologyItem[] = (res?.terminology || []).map((term: TerminologyItem) => ({
          ...omit(term, 'id'),
          isPortalOnly: true,
        }));
        return [...TERMINOLOGY_DEFAULT, ...serverTerms];
      }),
    );
  }
}
