import { TerminologyItem } from 'src/app/core/models/terminology.models';

export const TERMINOLOGY_DEFAULT: readonly TerminologyItem[] = [
  {
    single: 'Feedback',
    plural: 'Feedbacks',
    translateSingle: 'Feedback',
    translatePlural: 'Feedback',
    isPortalOnly: true,
  },
  {
    single: 'Company',
    plural: 'Companies',
    translateSingle: 'Company',
    translatePlural: 'Companies',
    isPortalOnly: true,
  },
] as const;

// export const TERMINOLOGY_SAP: readonly TerminologyItem[] = [
//   {
//     single: 'Feedback',
//     plural: 'Feedbacks',
//     translateSingle: 'Use Case',
//     translatePlural: 'Use Cases',
//     skipTransform: true,
//     isPortalOnly: true,
//   },
//   {
//     single: 'Category',
//     plural: 'Categories',
//     translateSingle: 'Idea',
//     translatePlural: 'Ideas',
//     skipTransform: true,
//     isPortalOnly: true,
//   },
// ] as const;

export const TERMINOLOGY_CRAFT_ITEMS: readonly TerminologyItem[] = [
  {
    single: 'Goal',
    plural: 'Goals',
    translateSingle: 'Objective', //user value
    translatePlural: 'Objectives', //user value
  },
  {
    single: 'Initiative',
    plural: 'Initiatives',
    translateSingle: 'Key Result',
    translatePlural: 'Key Results',
  },
  {
    single: 'Container',
    plural: 'Containers',
    translateSingle: 'Release',
    translatePlural: 'Releases',
  },
  {
    single: 'Theme',
    plural: 'Themes',
    translateSingle: 'Product',
    translatePlural: 'Products',
  },
  {
    single: 'Epic',
    plural: 'Epics',
    translateSingle: 'Epic',
    translatePlural: 'Epics',
  },
  {
    single: 'Story',
    plural: 'Stories',
    translateSingle: 'Feature',
    translatePlural: 'Features',
  },
  {
    single: 'Subtask',
    plural: 'Subtasks',
    translateSingle: 'Sub Feature',
    translatePlural: 'Sub Features',
  },
] as const;
