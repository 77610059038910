import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { CATEGORY_DELETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { DBCategory } from 'src/app/core/models/categories.models';
import { RouterExtService } from 'src/app/core/services/router-ext.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import {
  DeleteCategoryForm,
  DeleteOption,
} from 'src/app/shared/dialogs/delete-category-dialog/delete-categories-option-select/delete-categories-option-select.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

export type DeleteCategoryDialogInput = {
  category: DBCategory;
  disableRedirect?: boolean;
};

@Component({
  templateUrl: './delete-category-dialog.component.html',
  styleUrls: ['./delete-category-dialog.component.scss'],
})
export class DeleteCategoryDialogComponent extends Destroyer implements OnInit {
  public isRequestSending = false;
  public category: Readonly<DBCategory>;
  public DeleteOption = DeleteOption;

  public form: FormGroup<DeleteCategoryForm>;

  constructor(
    private router: Router,
    private routerExt: RouterExtService,
    private websocketService: WebSocketService,
    private categoriesStore: CategoriesStoreService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteCategoryDialogInput,
    private dialogRef: MatDialogRef<DeleteCategoryDialogComponent, DialogResult>,
  ) {
    super();
    this.category = this.data.category;
    this.form = new FormGroup(
      {
        option: new FormControl<DeleteOption | null>(
          this.category.ideasCnt > 0 ? null : DeleteOption.deleteAll,
          Validators.required,
        ),
        moveToCategoryId: new FormControl<string | null>(null),
      },
      (form: AbstractControl) => {
        const { option, moveToCategoryId } = form.value;
        return option === DeleteOption.moveTo && !moveToCategoryId ? { categoryRequired: true } : null;
      },
    );
  }

  public ngOnInit() {
    this.category = this.data.category;
    this.websocketService.message$
      .pipe(
        filter((msg) => {
          return (
            msg.event === CATEGORY_DELETE_EVENT && //
            msg.data?.categoryId &&
            msg.data.categoryId === this.category.id
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (!this.data.disableRedirect) {
          this.router.navigate(this.routerExt.getDefaultProductRoute(this.category.productKey));
        }
        this.dialogRef.close({ done: true });
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    if (!this.form.valid) return;

    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    const val = this.form.value;
    const newCategoryId = val.option === DeleteOption.moveTo ? val.moveToCategoryId : void 0;
    this.categoriesStore.delete(this.category.id, newCategoryId).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
