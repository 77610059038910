import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { StaticFilterType } from 'src/app/core/enums/StaticFilterType';
import { PeriodEnum } from 'src/app/core/models/period';

export type ServerTextFilterValue = { noValue?: boolean; text?: string };
export type ServerNumberFilterValue = { noValue?: boolean; max?: number; min?: number };
export type ServerDateFilterValue = { period?: PeriodEnum; from?: number; to?: number };

export function isNumberFilterValue(val: unknown): val is ServerNumberFilterValue {
  return !!val && typeof val === 'object' && ('noValue' in val || 'max' in val || 'min' in val);
}

export function isTextFilterValue(val: unknown): val is ServerTextFilterValue {
  return !!val && typeof val === 'object' && ('noValue' in val || 'text' in val);
}

export function isDateFilterValue(val: unknown): val is ServerDateFilterValue {
  return !!val && typeof val === 'object' && ('period' in val || 'from' in val || 'to' in val);
}

export type ServerFilterValue =
  | null
  | undefined
  | number
  | string
  | boolean
  | readonly string[]
  | ServerTextFilterValue
  | ServerDateFilterValue
  | ServerNumberFilterValue;

export type SortField = {
  readonly field: string;
  readonly fieldType: string;
  readonly label: string;
  readonly revert: boolean;
  readonly hideVotes?: boolean;
  readonly permission?: AccessLevel;
};

export type StaticFilter = {
  readonly id: StaticFilterType;
  readonly label: string;
  readonly isDefault?: boolean;
  readonly accessLevel: AccessLevel;
  readonly hideTrack?: boolean;
  readonly icon?: string;
  readonly total?: number;
};

export type FilterDescription = {
  readonly key: string;
  readonly type: 'array' | 'string' | 'number' | 'custom';
  readonly transform?: (value: string) => ServerFilterValue;
  readonly defaultValue: ServerFilterValue;
};
