import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBTag, Tag, TagsAddBulkRequest, TagsListRequest, TagsListResponse } from 'src/app/core/models/tags.models';
import { TagsBackendService } from 'src/app/core/services/backend/tags-backend.service';
import {
  TagsReqAddAction,
  TagsReqAddBulkAction,
  TagsReqAddBulkErrorAction,
  TagsReqAddBulkSuccessAction,
  TagsReqAddErrorAction,
  TagsReqAddSuccessAction,
  TagsReqDeleteAction,
  TagsReqDeleteErrorAction,
  TagsReqDeleteSuccessAction,
  TagsReqProductListAction,
  TagsReqProductListErrorAction,
  TagsReqProductListSuccessAction,
  TagsReqUpdateAction,
  TagsReqUpdateErrorAction,
  TagsReqUpdateSuccessAction,
} from 'src/app/core/store/actions/tags';
import { getTagsListSelector, getTagsSelector } from 'src/app/core/store/reducers/tags';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class TagsStoreService {
  public readonly map$ = this.store$.pipe(select(getTagsSelector));
  public readonly list$ = this.store$.pipe(select(getTagsListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private bs: TagsBackendService,
  ) {}

  public load(req: TagsListRequest): Observable<TagsListResponse> {
    this.store$.dispatch(new TagsReqProductListAction(req));

    return this.bs.getProductList(req).pipe(
      map((res) => {
        this.store$.dispatch(new TagsReqProductListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TagsReqProductListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(tag: Tag): Observable<DBTag> {
    this.store$.dispatch(new TagsReqAddAction(tag));

    return this.bs.add(tag).pipe(
      map((res) => {
        this.store$.dispatch(new TagsReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TagsReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public addBulk(productId: string, tags: readonly string[]): Observable<TagsListResponse> {
    const req: TagsAddBulkRequest = { productId, tags };
    this.store$.dispatch(new TagsReqAddBulkAction(req));

    return this.bs.addBulk(req).pipe(
      map((res) => {
        this.store$.dispatch(new TagsReqAddBulkSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TagsReqAddBulkErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(tag: DBTag): Observable<DBTag> {
    this.store$.dispatch(new TagsReqUpdateAction(tag));

    return this.bs.update(tag).pipe(
      map((res) => {
        this.store$.dispatch(new TagsReqUpdateSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TagsReqUpdateErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(tag: DBTag): Observable<string> {
    this.store$.dispatch(new TagsReqDeleteAction(tag.id));

    return this.bs.delete(tag).pipe(
      map((res) => {
        this.store$.dispatch(new TagsReqDeleteSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new TagsReqDeleteErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
