import { ActionReducerMap } from '@ngrx/store';
import * as fromUser from './auth';
import * as fromCategories from './categories';
import * as fromCompanies from './companies';
import * as fromCompanyUsers from './company-users';
import * as fromCustomFields from './custom-fields';
import * as fromFilters from './filters';
import * as fromForms from './forms';
import * as fromIdeas from './ideas';
import * as fromImportances from './importances';
import * as fromIstatuses from './inner-statuses';
import * as fromPermissions from './permissions';
import * as fromPortal from './portal';
import * as fromProducts from './products';
import * as fromTags from './tags';
import * as fromTerms from './terms';
import * as fromWstatuses from './workflow-statuses';

export type CoreState = {
  categoriesState: fromCategories.CategoriesState;
  companiesState: fromCompanies.CompaniesState;
  formsState: fromForms.FormsState;
  companyUsersState: fromCompanyUsers.CompanyUsersState;
  customFieldsState: fromCustomFields.CustomFieldsState;
  filtersState: fromFilters.FiltersState;
  ideasState: fromIdeas.IdeasState;
  importancesState: fromImportances.ImportancesState;
  innerStatusesState: fromIstatuses.InnerStatusesState;
  permissionsState: fromPermissions.PermissionsState;
  portalState: fromPortal.PortalState;
  productsState: fromProducts.ProductsState;
  tagsState: fromTags.TagsState;
  authState: fromUser.AuthState;
  workflowStatusesState: fromWstatuses.WorkflowStatusesState;
  termsState: fromTerms.TerminologyState;
};

export const reducers = {
  categoriesState: fromCategories.reducer,
  companiesState: fromCompanies.reducer,
  formsState: fromForms.reducer,
  companyUsersState: fromCompanyUsers.reducer,
  customFieldsState: fromCustomFields.reducer,
  filtersState: fromFilters.reducer,
  ideasState: fromIdeas.reducer,
  importancesState: fromImportances.reducer,
  innerStatusesState: fromIstatuses.reducer,
  permissionsState: fromPermissions.reducer,
  portalState: fromPortal.reducer,
  productsState: fromProducts.reducer,
  tagsState: fromTags.reducer,
  authState: fromUser.reducer,
  workflowStatusesState: fromWstatuses.reducer,
  termsState: fromTerms.reducer,
} as ActionReducerMap<CoreState>;
