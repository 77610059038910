import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { USER_ROLES_DISPLAYED_LIST, UserRole, UserRoleView } from 'src/app/core/enums/UserRole';
import { DBCompany } from 'src/app/core/models/companies.models';
import { CompanyUser, PortalUser } from 'src/app/core/models/users-models';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { CompaniesUsersStoreService } from 'src/app/core/store/services/companies-store-users.service';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { PermissionsStoreService } from 'src/app/core/store/services/permissions-store.service';
import { ChangeUserRoleDialogComponent } from 'src/app/shared/components/craft-manage-user-menu/change-user-role-dialog/change-user-role-dialog.component';
import { DeleteCompanyUserDialogComponent } from 'src/app/shared/components/craft-manage-user-menu/delete-company-user-dialog/delete-company-user-dialog.component';
import { MoveUserToCompanyDialogComponent } from 'src/app/shared/components/craft-manage-user-menu/move-user-to-company-dialog/move-user-to-company-dialog.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  selector: 'craft-manage-user-menu',
  templateUrl: './craft-manage-user-menu.component.html',
  styleUrls: ['./craft-manage-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftManageUserMenuComponent extends Destroyer implements OnInit {
  @Input() public user: PortalUser | CompanyUser;
  @Input() public showChangeRole = false;
  @Input() public showDeleteUser = true;
  @Input() public showMoveToCompany = false;
  @Output() public userDeleted = new EventEmitter<void>();
  @Output() public movedToCompany = new EventEmitter<void>();
  @Output() public roleChanged = new EventEmitter<void>();

  public readonly roles = USER_ROLES_DISPLAYED_LIST.slice();
  public readonly companies$: Observable<DBCompany[]>;
  public readonly searchControl = new FormControl<string>('');

  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: AuthStoreService,
    private readonly permissionsStore: PermissionsStoreService,
    private readonly usersStore: CompaniesUsersStoreService,
    private readonly companyStore: CompaniesStoreService,
  ) {
    super();
    this.companies$ = combineLatest({
      searchTerm: this.searchControl.valueChanges.pipe(startWith('')),
      companies: companyStore.list$,
    }).pipe(
      map(({ companies, searchTerm }) => {
        const term = (searchTerm || '').toLocaleLowerCase();

        return companies.filter((c) => {
          if (c.id === (this.user as CompanyUser | undefined)?.companyId) return false;
          if (!term) return true;
          return c.name.toLocaleLowerCase().includes(term);
        });
      }),
    );
  }

  public ngOnInit(): void {
    combineLatest([this.companyStore.list$, this.authService.currentUser$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([companies, currentUser]) => {
        const userRole = currentUser?.iportalRole || UserRole.Anonymous;
        const isAdmin = this.authService.authorize(AccessLevel.Admin, userRole);

        const filteredCompanies = companies.filter((c) => {
          return c.id !== currentUser?.companyId && c.id !== (this.user as CompanyUser)?.companyId;
        });

        this.showChangeRole = this.showChangeRole && isAdmin;
        this.showDeleteUser = this.showDeleteUser && currentUser?.id !== this.user?.id;
        this.showMoveToCompany = this.showMoveToCompany && filteredCompanies.length > 0;
      });
  }

  public identify(index: number, item?: DBCompany): string | number {
    return item?.id || index;
  }

  public changeUserRole(role: UserRoleView) {
    this.dialog
      .open(ChangeUserRoleDialogComponent, { data: { user: this.user, targetRole: role } })
      .afterClosed()
      .pipe(
        filter((res): res is DialogResult => !!res?.done),
        switchMap(() => {
          return this.permissionsStore.promoteUserToRole({
            email: this.user.email!,
            iportalRole: role.id,
            fromCompanyId: (this.user as CompanyUser).companyId,
          });
        }),
      )
      .subscribe(() => {
        this.roleChanged.next();
      });
  }

  public moveToCompany(company: DBCompany) {
    this.dialog
      .open(MoveUserToCompanyDialogComponent, { data: { user: this.user, targetCompanyId: company.id } })
      .afterClosed()
      .pipe(
        filter((res): res is DialogResult => !!res?.done),
        switchMap(() => {
          return this.usersStore.moveToCompany({
            fromCompanyId: (this.user as CompanyUser).companyId,
            toCompanyId: company.id,
            email: this.user.email!,
          });
        }),
      )
      .subscribe(() => {
        this.movedToCompany.next();
      });
  }

  public confirmDeleteUser() {
    this.dialog
      .open(DeleteCompanyUserDialogComponent, { data: this.user })
      .afterClosed()
      .pipe(
        filter((res): res is DialogResult => !!res?.done),
        switchMap(() => {
          return this.user.userType === 'company'
            ? this.usersStore.deleteUser(this.user)
            : this.permissionsStore.deleteUser(this.user as PortalUser);
        }),
      )
      .subscribe(() => {
        this.userDeleted.next();
      });
  }
}
