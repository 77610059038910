import { ChangeDetectorRef, Component, forwardRef, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AuthUser } from 'src/app/core/models/auth.models';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBCompany } from 'src/app/core/models/companies.models';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { BaseSingleSelectComponent } from '../craft-single-select/craft-single-select.component';

@Component({
  selector: 'craft-companies-select',
  templateUrl: './craft-companies-select.component.html',
  styleUrls: ['./craft-companies-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftCompaniesSelectComponent),
      multi: true,
    },
  ],
})
export class CraftCompaniesSelectComponent extends BaseSingleSelectComponent implements OnInit, OnChanges {
  public items: DBCompany[] = [];

  public currentUser?: AuthUser;
  public companiesMap: StringTMap<DBCompany | null> | null = {};

  constructor(
    changeDetectorRef: ChangeDetectorRef,

    private companiesStore: CompaniesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
    this.searchVisibleCount = 5;
  }

  public ngOnInit() {
    this.companiesStore.activeList$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      this.setItems(companies, this.selectedId);
    });

    this.companiesStore.map$.pipe(takeUntil(this.destroy$)).subscribe((companiesMap) => {
      this.companiesMap = companiesMap;
    });
  }
}
