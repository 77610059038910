import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, takeUntil } from 'rxjs';
import { DBCompany } from 'src/app/core/models/companies.models';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import { BaseSingleSelectComponent } from 'src/app/shared/components/craft-single-select/craft-single-select.component';

export enum DeleteOption {
  moveTo = 1,
  deleteAll = 2,
}

@Component({
  selector: 'craft-delete-companies-option-select',
  templateUrl: './delete-companies-option-select.component.html',
  styleUrls: ['./delete-companies-option-select.component.scss'],
})
export class DeleteCompaniesOptionSelectComponent extends BaseSingleSelectComponent implements OnInit {
  @Input() public deletedCompanyIds: string[];
  @Input() public form: FormGroup<{
    option: FormControl<DeleteOption | null>;
    moveToCompany: FormControl<string | null>;
  }>;

  public DeleteOption = DeleteOption;
  public isMutltipleWorkspaces: boolean;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private productStore: ProductsStoreService,
    private companiesStore: CompaniesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    combineLatest([
      this.companiesStore.activeList$,
      this.productStore.isMultipleWorkspacesPortal$, //
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([activeCompanies, isMutltipleWorkspaces]) => {
        const companies = activeCompanies.filter((c) => !this.deletedCompanyIds.includes(c.id));
        const selectedCompany = companies.length > 0 ? companies[0].id : null;
        const selectedOption = isMutltipleWorkspaces
          ? DeleteOption.deleteAll
          : selectedCompany
          ? null
          : DeleteOption.deleteAll;

        this.isMutltipleWorkspaces = isMutltipleWorkspaces;
        this.setItems(companies, void 0);
        this.form.patchValue({ option: selectedOption });
      });
  }

  public selectItem(company: DBCompany) {
    super.selectItem(company);
    this.form.patchValue({ option: DeleteOption.moveTo, moveToCompany: company.id });
  }
}
