<ng-container>
  <div class="dialog-desc">
    {{
      deletedCompanyIds.length
        | i18nPlural
          : {
              '=1': 'settings.companies.deleteCmpQuestionSingle',
              other: 'settings.companies.deleteCmpQuestionPlural'
            }
        | craftTerm
    }}
    <br />
    {{ 'settings.companies.deleteCmpQuestion2' | craftTerm }}
  </div>
  <mat-radio-group [formControl]="form.controls.option">
    <div
      class="d-inline-flex radio-button-wrap"
      [matTooltip]="'Allowed only in portals that are associated to a single workspace'"
      [matTooltipDisabled]="!isMutltipleWorkspaces || items.length === 0"
    >
      <mat-radio-button
        class="radio-btn-blue"
        [value]="DeleteOption.moveTo"
        [disabled]="isMutltipleWorkspaces || items.length === 0"
      >
        {{ 'settings.companies.deleteCmpOpt1' | craftTerm }}
      </mat-radio-button>

      <button
        class="btn btn-trigger clear-disable ellipsis"
        type="button"
        [disabled]="isMutltipleWorkspaces || items.length === 0"
        (menuOpened)="onMenuOpened()"
        (menuClosed)="onMenuClosed()"
        [matMenuTriggerFor]="menuCategories"
      >
        <span class="ellipsis" [matTooltip]="selectedItem?.name" matTooltipPosition="above" matTooltipShowDelay="1000">
          {{ selectedItem?.name || 'company.select' | craftTerm }}
        </span>
        <craft-icon class="svg-icon text-size-11 ml-4" name="arrows--arrow-head-down-sm"></craft-icon>
      </button>
    </div>
    <br />
    <mat-radio-button class="radio-btn-blue" [value]="DeleteOption.deleteAll">
      {{ 'settings.companies.deleteCmpOpt2' | craftTerm }}
    </mat-radio-button>
  </mat-radio-group>
</ng-container>

<mat-menu #menuCategories="matMenu">
  <ng-template matMenuContent="">
    <div class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input
        type="text"
        #searchInput
        (click)="$event.stopPropagation()"
        [placeholder]="searchPlaceholder"
        [formControl]="searchControl"
      />
    </div>

    <button
      mat-menu-item=""
      *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
      [ngClass]="{ selected: selectedId === item[idFieldName] }"
      (click)="selectItem(item)"
      craftDisableFocus=""
      type="button"
    >
      <span class="ellipsis">{{ item[labelFieldName] }}</span>
    </button>
  </ng-template>
</mat-menu>
