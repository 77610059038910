import { Injectable } from '@angular/core';
import { isEmpty, isUndefined, omitBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IDEAS_REQ_TO_QUERY_PARAMS, QUERY_PARAMS_TO_IDEAS_REQ } from 'src/app/core/dictionaries/ideas-filters-table';
import { IDEAS_SORT_TYPES } from 'src/app/core/dictionaries/ideas-sort-fields';
import { STATIC_FILTERS } from 'src/app/core/dictionaries/static-filters';
import { StringAnyMap, StringStringMap } from 'src/app/core/models/common.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import {
  FilterDescription,
  isDateFilterValue,
  isNumberFilterValue,
  isTextFilterValue,
  ServerFilterValue,
  SortField,
  StaticFilter,
} from 'src/app/core/models/filters.models';
import { IdeasListRequest } from 'src/app/core/models/idea.models';
import { PeriodEnum } from 'src/app/core/models/period';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { FiltersStoreService } from 'src/app/core/store/services/filters-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { WorkflowStatusesStoreService } from 'src/app/core/store/services/workflow-statuses-store.service';

@Injectable({
  providedIn: 'root',
})
export class IdeasSortFiltersService {
  public readonly sort$: Observable<SortField | undefined>;
  public readonly sortFields$: Observable<readonly SortField[]>;
  public readonly workflowStatuses$ = this.workflowStatusesStore.map$;

  constructor(
    private ups: UserPermissionsService,
    private portalStore: PortalStoreService,
    private filtersStore: FiltersStoreService,
    private customFieldsStore: CustomFieldsStoreService,
    private workflowStatusesStore: WorkflowStatusesStoreService,
  ) {
    this.sort$ = this.filtersStore.sort$.pipe(
      map((sortType) => {
        const isRevert = sortType[0] === '-';
        if (isRevert) sortType = sortType.substr(1);
        const res = IDEAS_SORT_TYPES.find((st) => st.field === sortType);
        return res ? { ...res, revert: isRevert } : void 0;
      }),
    );

    this.sortFields$ = of(void 0).pipe(
      map(() => {
        const res = IDEAS_SORT_TYPES.filter((st) => !st.permission || this.ups.authorize(st.permission));
        return this.hideVotes ? res.filter((st) => !st.hideVotes) : res;
      }),
    );
  }

  public getStaticFilters(): StaticFilter[] {
    return STATIC_FILTERS.reduce((res, sn) => {
      if (this.isValidStaticFilter(sn)) {
        res.push(sn);
      }
      return res;
    }, [] as StaticFilter[]);
  }

  public isValidStaticFilter(node: StaticFilter): boolean {
    return (!this.hideTrack || !node.hideTrack) && this.ups.authorize(node.accessLevel);
  }

  public routeParamsToIdeasReq(queryParams: StringStringMap): Observable<Partial<IdeasListRequest>> {
    return this.customFieldsStore.map$.pipe(
      map((customFieldsMap) => {
        const rqgularFilters = Object.keys(QUERY_PARAMS_TO_IDEAS_REQ).reduce<StringAnyMap>((res, key) => {
          const filterDescription = QUERY_PARAMS_TO_IDEAS_REQ[key];
          res[filterDescription.key] = this.parseReqularFilterQueryParam(queryParams[key], filterDescription);
          return res;
        }, {});

        const customFilters = Object.keys(queryParams)
          .filter((key) => key.startsWith('custom.'))
          .reduce<StringAnyMap>((res, key) => {
            const customFieldId = key.replace('custom.', '');
            const customField = customFieldsMap[customFieldId];
            if (customField) {
              res[customFieldId] = this.parseCustomFieldQueryParam(queryParams[key], customField);
            }
            return res;
          }, {});

        return { ...rqgularFilters, custom: customFilters };
      }),
    );
  }

  public ideasReqToRouteParams(req: Partial<IdeasListRequest>): Observable<StringAnyMap> {
    return this.customFieldsStore.map$.pipe(
      map((customFields) => {
        return Object.keys(req).reduce<StringAnyMap>((res, reqKey) => {
          const filterVal: ServerFilterValue = req[reqKey as keyof IdeasListRequest];
          if (reqKey.startsWith('custom.')) {
            const customFieldId = reqKey.replace('custom.', '');
            res[reqKey] = this.stringifyFilterValue(filterVal, customFields[customFieldId]);
          } else {
            const key = IDEAS_REQ_TO_QUERY_PARAMS[reqKey].key;
            res[key] = this.stringifyFilterValue(filterVal);
          }
          return res;
        }, {});
      }),
    );
  }

  public makeIdeasRequest(req: IdeasListRequest): Observable<Readonly<IdeasListRequest>> {
    return this.filtersStore.state$.pipe(take(1)).pipe(
      map((state) => {
        return {
          ...state,
          ...req,
          page: req.page || state.page,
          limit: req.limit || state.limit,
        };
      }),
    );
  }

  private stringifyFilterValue(val?: ServerFilterValue, customField?: CustomField) {
    if (Array.isArray(val) && val.length > 0) {
      return val.join(',');
    }

    if ((typeof val === 'string' && val) || typeof val === 'number') {
      return val;
    }

    if (customField?.type === 'NUMBER' && isNumberFilterValue(val)) {
      const noValue = val.noValue ? 1 : '';
      const min = typeof val.min === 'number' ? val.min.toString() : '';
      const max = typeof val.max === 'number' ? val.max.toString() : '';
      return noValue || min || max ? `${noValue}_${min}_${max}` : void 0;
    }

    if (customField?.type === 'TEXT' && isTextFilterValue(val)) {
      const noValue = val.noValue ? 1 : '';
      const text = typeof val.text === 'string' ? val.text : '';
      return noValue || text ? `${noValue}_${text}` : void 0;
    }

    if (customField?.type === 'DATE' && isDateFilterValue(val)) {
      const to = typeof val.to === 'number' ? val.to : '';
      const from = typeof val.from === 'number' ? val.from : '';
      const period = typeof val.period === 'string' ? val.period : '';
      return period || from || to ? `${period}_${from}_${to}` : void 0;
    }

    return;
  }

  private parseReqularFilterQueryParam(value: string, filterDescription: FilterDescription): ServerFilterValue {
    if (!value) {
      return filterDescription.defaultValue;
    }

    if (filterDescription.type === 'array') {
      return value.split(',');
    }

    if (filterDescription.transform) {
      return filterDescription.transform(value);
    }

    return value;
  }

  private parseCustomFieldQueryParam(value: string, customField: CustomField): ServerFilterValue {
    if (!value) {
      return void 0;
    }

    if (customField.type === 'MULTISELECT' || customField.type === 'SINGLESELECT') {
      const optionsSet = new Set((customField.options || []).map((opt) => opt.id));
      return this.clearValues(value.split(',').filter((v) => v && optionsSet.has(v)));
    }

    if (customField.type === 'NUMBER') {
      const [noValue, min, max] = value.split('_');
      return this.clearValues({
        noValue: noValue === '1' || void 0,
        min: Number.parseFloat(min) || void 0,
        max: Number.parseFloat(max) || void 0,
      });
    }

    if (customField.type === 'TEXT') {
      const [noValue, text] = value.split('_');
      return this.clearValues({
        noValue: noValue === '1' || void 0,
        text: text || void 0,
      });
    }

    if (customField.type === 'DATE') {
      const [period, from, to] = value.split('_');
      return this.clearValues({
        to: Number.parseInt(to) || void 0,
        from: Number.parseInt(from) || void 0,
        period: (PeriodEnum as StringStringMap)[period] ? (period as PeriodEnum) : PeriodEnum.custom,
      });
    }

    return value;
  }

  private get hideTrack(): boolean {
    return !!this.portalStore.portalSnapshot?.hideTrack;
  }

  private get hideVotes(): boolean {
    return !!this.portalStore.portalSnapshot?.hideVotes;
  }

  private clearValues(values: object | readonly string[]) {
    if (Array.isArray(values)) {
      return values.length > 0 ? values : void 0;
    }

    const val = omitBy(values, isUndefined);
    return isEmpty(val) ? void 0 : val;
  }
}
