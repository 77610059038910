import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { combineLatest, take } from 'rxjs';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { UserRole } from 'src/app/core/enums/UserRole';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';

@Directive({ selector: '[craftCompanyPermission]' })
export class CraftCompanyPermissionDirective {
  private hasView = false;
  private accessLevel: AccessLevel;
  private elseTemplateRef: TemplateRef<any> | null;
  private thenViewRef: EmbeddedViewRef<any> | null = null;
  private elseViewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private viewContainer: ViewContainerRef,
    private thenTemplateRef: TemplateRef<any>,
    private authStore: AuthStoreService,
    private portalStore: PortalStoreService,
    private productStore: ProductsStoreService,
    private companiesStore: CompaniesStoreService,
  ) {}

  @Input()
  set craftCompanyPermission(accessLevel: string) {
    this.thenViewRef = null;
    this.accessLevel = (AccessLevel as any)[accessLevel] || AccessLevel.AccountOwner;
    this.checkPermission();
  }

  @Input()
  set craftCompanyPermissionElse(templateRef: TemplateRef<any> | null) {
    this.elseViewRef = null;
    this.elseTemplateRef = templateRef;
    this.checkPermission();
  }

  private checkPermission() {
    combineLatest([
      this.portalStore.portal$,
      this.authStore.currentUser$,
      this.companiesStore.activeList$,
      this.productStore.isMultipleWorkspacesPortal$,
    ])
      .pipe(take(1))
      .subscribe(([portal, currentUser, companies, isMultipleWorkspacesPortal]) => {
        if (isMultipleWorkspacesPortal) {
          this.updateTemplate(false);
          return;
        }

        if (!portal?.restrictedAccess) {
          this.updateTemplate(false);
          return;
        }

        if (companies.length === 0) {
          this.updateTemplate(false);
          return;
        }

        const role = currentUser ? currentUser.iportalRole : UserRole.Anonymous;
        if (!this.authStore.authorize(this.accessLevel, role)) {
          this.updateTemplate(false);
          return;
        }

        this.updateTemplate(true);
      });
  }

  private updateTemplate(condition: boolean) {
    if (condition) {
      if (!this.thenViewRef) {
        this.elseViewRef = null;
        this.viewContainer.clear();
        this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
      }
    } else if (!this.elseViewRef) {
      this.thenViewRef = null;
      this.viewContainer.clear();
      if (this.elseTemplateRef) {
        this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }
}
